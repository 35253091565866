import { library } from "@fortawesome/fontawesome-svg-core";

// This import situation isn't ideal but reduces bundle size
// Possible solution: https://github.com/FortAwesome/react-fontawesome/issues/70#issuecomment-411165298

// pro-solid-svg-icons
import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare";
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faFolderClosed } from "@fortawesome/pro-solid-svg-icons/faFolderClosed";
import { faFolderOpen } from "@fortawesome/pro-solid-svg-icons/faFolderOpen";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faSquare } from "@fortawesome/pro-solid-svg-icons/faSquare";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons/faEllipsis";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faHorizontalRule } from "@fortawesome/pro-solid-svg-icons/faHorizontalRule";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { faRotateLeft } from "@fortawesome/pro-solid-svg-icons/faRotateLeft";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons/faRotateRight";
import { faPencil as fasPencil } from "@fortawesome/pro-solid-svg-icons/faPencil";
import { faFileImport } from "@fortawesome/pro-solid-svg-icons/faFileImport";
import { faTable } from "@fortawesome/pro-solid-svg-icons/faTable";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faMap } from "@fortawesome/pro-solid-svg-icons/faMap";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons/faUserTie";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faGear } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faSort as fasSort } from "@fortawesome/pro-solid-svg-icons/faSort";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faScreenUsers } from "@fortawesome/pro-solid-svg-icons/faScreenUsers";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faCheck as fasCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faChevronsUp } from "@fortawesome/pro-solid-svg-icons/faChevronsUp";
import { faChevronsDown } from "@fortawesome/pro-solid-svg-icons/faChevronsDown";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons/faCalendarDays";
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faTag } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";
import { faPalette } from "@fortawesome/pro-solid-svg-icons/faPalette";
import { faSortUp } from "@fortawesome/pro-solid-svg-icons/faSortUp";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faListUl } from "@fortawesome/pro-solid-svg-icons/faListUl";
import { faBug } from "@fortawesome/pro-solid-svg-icons/faBug";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { faBooks } from "@fortawesome/pro-solid-svg-icons/faBooks";
import { faFile } from "@fortawesome/pro-solid-svg-icons/faFile";
import { faLock as fasLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/pro-solid-svg-icons/faUnlock";
import { faLockOpen as fasLockOpen } from "@fortawesome/pro-solid-svg-icons/faLockOpen";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { faUpload } from "@fortawesome/pro-solid-svg-icons/faUpload";
import { faKey as fasKey } from "@fortawesome/pro-solid-svg-icons/faKey";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faFileArrowDown } from "@fortawesome/pro-solid-svg-icons/faFileArrowDown";
import { faHouseChimney } from "@fortawesome/pro-solid-svg-icons/faHouseChimney";
import { faImage } from "@fortawesome/pro-solid-svg-icons/faImage";
import { faLayerGroup as fasLayerGroup } from "@fortawesome/pro-solid-svg-icons/faLayerGroup";
import { faPallet } from "@fortawesome/pro-solid-svg-icons/faPallet";
import { faFolders as fasFolders } from "@fortawesome/pro-solid-svg-icons/faFolders";
import { faDatabase } from "@fortawesome/pro-solid-svg-icons/faDatabase";
import { faDiamond } from "@fortawesome/pro-solid-svg-icons/faDiamond";
import { faText } from "@fortawesome/pro-solid-svg-icons/faText";
import { faCube as fasCube } from "@fortawesome/pro-solid-svg-icons/faCube";
import { faAperture } from "@fortawesome/pro-solid-svg-icons/faAperture";
import { faBold } from "@fortawesome/pro-solid-svg-icons/faBold";
import { faCompass } from "@fortawesome/pro-solid-svg-icons/faCompass";
import { faInfoCircle as fasCircleInfo } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faRulerTriangle as fasRulerTriangle } from "@fortawesome/pro-solid-svg-icons/faRulerTriangle";
import { faMessageLines as fasMessageLines } from "@fortawesome/pro-solid-svg-icons/faMessageLines";
import { faRulerHorizontal } from "@fortawesome/pro-solid-svg-icons/faRulerHorizontal";
import { faPersonWalking } from "@fortawesome/pro-solid-svg-icons/faPersonWalking";
import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons/faDoorOpen";
import { faRulerCombined } from "@fortawesome/pro-solid-svg-icons/faRulerCombined";
import { faTally } from "@fortawesome/pro-solid-svg-icons/faTally";
import { faTrashUndo } from "@fortawesome/pro-solid-svg-icons/faTrashUndo";
import { faFileUpload as fasFileUpload } from "@fortawesome/pro-solid-svg-icons/faFileUpload";
import { faUser as fasUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faArrowPointer as fasArrowPointer } from "@fortawesome/pro-solid-svg-icons/faArrowPointer";
import { faClouds as falClouds } from "@fortawesome/pro-solid-svg-icons/faClouds";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import { faAngle } from "@fortawesome/pro-solid-svg-icons/faAngle";
import { faCopy as fasCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faShareNodes as fasShareNodes } from "@fortawesome/pro-solid-svg-icons/faShareNodes";
import { faPencilSlash } from "@fortawesome/pro-solid-svg-icons";
import { faFileInvoice as fasFileInvoice } from "@fortawesome/pro-solid-svg-icons/faFileInvoice";
import { faCreditCard as fasCreditCard } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faFileInvoiceDollar as fasFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar";
import { fa1 as fas1 } from "@fortawesome/pro-solid-svg-icons/fa1";
import { fa2 as fas2 } from "@fortawesome/pro-solid-svg-icons/fa2";
import { fa3 as fas3 } from "@fortawesome/pro-solid-svg-icons/fa3";
import { fa4 as fas4 } from "@fortawesome/pro-solid-svg-icons/fa4";
import { fa5 as fas5 } from "@fortawesome/pro-solid-svg-icons/fa5";
import { faTelescope as fasTelescope } from "@fortawesome/pro-solid-svg-icons/faTelescope";
import { faThoughtBubble as fasThoughtBubble } from "@fortawesome/pro-solid-svg-icons/faThoughtBubble";
import { faRugbyBall as fasRugbyBall } from "@fortawesome/pro-solid-svg-icons/faRugbyBall";
import { faRoad as fasRoad } from "@fortawesome/pro-solid-svg-icons/faRoad";
import { faPartyHorn as fasPartyHorn } from "@fortawesome/pro-solid-svg-icons/faPartyHorn";
import { faPanorama as fasPanorama } from "@fortawesome/pro-solid-svg-icons/faPanorama";
import { faRing as fasRing } from "@fortawesome/pro-solid-svg-icons/faRing";
import { faThumbtack as fasThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faMagnifyingGlass as fasMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faCalendarRange as fasCalendarRange } from "@fortawesome/pro-solid-svg-icons/faCalendarRange";
import { faArrowLeft as fasArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faChevronRight as fasChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faDownload as fasDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faGripDots as fasGripDots } from "@fortawesome/pro-solid-svg-icons/faGripDots";
import { faPenToSquare as farPenToSquare } from "@fortawesome/pro-solid-svg-icons/faPenToSquare";
import { faCar as fasCar } from "@fortawesome/pro-solid-svg-icons/faCar";
import { faBicycle as fasBicycle } from "@fortawesome/pro-solid-svg-icons/faBicycle";
import { faClock as fasClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faTableCellsLarge as fasTableCellsLarge } from "@fortawesome/pro-solid-svg-icons/faTableCellsLarge";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import { faLocationDot as fasLocationDot } from "@fortawesome/pro-solid-svg-icons/faLocationDot";
import { faBell as fasBell } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faPlus as fasPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";

// pro-regular-svg-icons
import { faFilter as farFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faMap as farMap } from "@fortawesome/pro-regular-svg-icons/faMap";
import { faGear as farGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import { faUsers as farUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faCircleInfo as farCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faCircleNotch as farCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faEarthEurope } from "@fortawesome/pro-regular-svg-icons/faEarthEurope";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { faCamera } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons/faPenToSquare";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faArrowsUpDownLeftRight } from "@fortawesome/pro-regular-svg-icons/faArrowsUpDownLeftRight";
import { faFolderPlus } from "@fortawesome/pro-regular-svg-icons/faFolderPlus";
import { faExpand } from "@fortawesome/pro-regular-svg-icons/faExpand";
import { faRulerTriangle as farRulerTriangle } from "@fortawesome/pro-regular-svg-icons/faRulerTriangle";
import { faVectorSquare } from "@fortawesome/pro-regular-svg-icons/faVectorSquare";
import { faSort as farSort } from "@fortawesome/pro-regular-svg-icons/faSort";
import { faMessageLines as farMessageLines } from "@fortawesome/pro-regular-svg-icons/faMessageLines";
import { faMessagePlus as farMessagePlus } from "@fortawesome/pro-regular-svg-icons/faMessagePlus";
import { faObjectUngroup } from "@fortawesome/pro-regular-svg-icons/faObjectUngroup";
import { faPrint } from "@fortawesome/pro-regular-svg-icons/faPrint";
import { faLinkSlash } from "@fortawesome/pro-regular-svg-icons/faLinkSlash";
import { faXmark as farXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faCrosshairs } from "@fortawesome/pro-regular-svg-icons/faCrosshairs";
import { faRotateLeft as farRotateLeft } from "@fortawesome/pro-regular-svg-icons/faRotateLeft";
import { faRotateRight as farRotateRight } from "@fortawesome/pro-regular-svg-icons/faRotateRight";
import { faMountain } from "@fortawesome/pro-regular-svg-icons/faMountain";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faCube } from "@fortawesome/pro-regular-svg-icons/faCube";
import { faCalendar as farCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faClipboard } from "@fortawesome/pro-regular-svg-icons/faClipboard";
import { faArrowUpRightFromSquare as farArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare";
import { faMinus as farMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faTag as farTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faShareNodes } from "@fortawesome/pro-regular-svg-icons/faShareNodes";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons/faFilePlus";
import { faChevronsLeft } from "@fortawesome/pro-regular-svg-icons/faChevronsLeft";
import { faChevronDown as farChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faScreenUsers as farScreenUsers } from "@fortawesome/pro-regular-svg-icons/faScreenUsers";
import { faCrown as farCrown } from "@fortawesome/pro-regular-svg-icons/faCrown";
import { faFileArrowDown as farFileArrowDown } from "@fortawesome/pro-regular-svg-icons/faFileArrowDown";
import { faFileArrowUp as farFileArrowUp } from "@fortawesome/pro-regular-svg-icons/faFileArrowUp";
import { faHouseChimney as farHouseChimney } from "@fortawesome/pro-regular-svg-icons/faHouseChimney";
import { faCircleXmark as farCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { faFolders } from "@fortawesome/pro-regular-svg-icons/faFolders";
import { faFunction } from "@fortawesome/pro-regular-svg-icons/faFunction";
import { faCircle as farCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faTableCellsLarge } from "@fortawesome/pro-regular-svg-icons/faTableCellsLarge";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faPencil as farPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faCalendarDays as farCalendarDays } from "@fortawesome/pro-regular-svg-icons/faCalendarDays";
import { faDrawPolygon as farDrawPolygon } from "@fortawesome/pro-regular-svg-icons/faDrawPolygon";
import { faScribble as farScribble } from "@fortawesome/pro-regular-svg-icons/faScribble";
import { faUserSlash } from "@fortawesome/pro-regular-svg-icons/faUserSlash";
import { faPassport } from "@fortawesome/pro-regular-svg-icons/faPassport";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons/faLocationDot";
import { faLocationCrosshairs } from "@fortawesome/pro-regular-svg-icons/faLocationCrosshairs";
import { faPhoneFlip as farPhoneFlip } from "@fortawesome/pro-regular-svg-icons/faPhoneFlip";
import { faClouds } from "@fortawesome/pro-regular-svg-icons/faClouds";
import { faCircleDot } from "@fortawesome/pro-regular-svg-icons/faCircleDot";
import { faFileSpreadsheet } from "@fortawesome/pro-regular-svg-icons/faFileSpreadsheet";
import { faUp } from "@fortawesome/pro-regular-svg-icons/faUp";
import { faDown } from "@fortawesome/pro-regular-svg-icons/faDown";
import { faLeft } from "@fortawesome/pro-regular-svg-icons/faLeft";
import { faRight } from "@fortawesome/pro-regular-svg-icons/faRight";
import { faUpDownLeftRight } from "@fortawesome/pro-regular-svg-icons/faUpDownLeftRight";
import { faMagnifyingGlassPlus } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus";
import { faMagnifyingGlassMinus } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { fa1 as far1 } from "@fortawesome/pro-regular-svg-icons/fa1";
import { fa2 as far2 } from "@fortawesome/pro-regular-svg-icons/fa2";
import { fa3 as far3 } from "@fortawesome/pro-regular-svg-icons/fa3";
import { fa4 as far4 } from "@fortawesome/pro-regular-svg-icons/fa4";
import { fa5 as far5 } from "@fortawesome/pro-regular-svg-icons/fa5";
import { faTelescope as farTelescope } from "@fortawesome/pro-regular-svg-icons/faTelescope";
import { faThoughtBubble as farThoughtBubble } from "@fortawesome/pro-regular-svg-icons/faThoughtBubble";
import { faRugbyBall as farRugbyBall } from "@fortawesome/pro-regular-svg-icons/faRugbyBall";
import { faRoad as farRoad } from "@fortawesome/pro-regular-svg-icons/faRoad";
import { faPartyHorn as farPartyHorn } from "@fortawesome/pro-regular-svg-icons/faPartyHorn";
import { faPanorama as farPanorama } from "@fortawesome/pro-regular-svg-icons/faPanorama";
import { faRing as farRing } from "@fortawesome/pro-regular-svg-icons/faRing";
import { faThumbtack as farThumbtack } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import { faCalendarRange as farCalendarRange } from "@fortawesome/pro-regular-svg-icons/faCalendarRange";
import { faCheckCircle as farCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faUserLock as farUserLock } from "@fortawesome/pro-regular-svg-icons/faUserLock";
import { faCircleUp as farCircleUp } from "@fortawesome/pro-regular-svg-icons/faCircleUp";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faRuler } from "@fortawesome/pro-regular-svg-icons/faRuler";
import { faCircleQuestion as farCircleQuestion } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faCircleArrowUp as farCircleArrowUp } from "@fortawesome/pro-regular-svg-icons/faCircleArrowUp";
import { faCirclePlus as farCirclePlus } from "@fortawesome/pro-regular-svg-icons/faCirclePlus";
import { faFolder as farFolder } from "@fortawesome/pro-regular-svg-icons";
import { faDownload as farDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faBan as farBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faUpload as farUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faRoute as farRoute } from "@fortawesome/pro-regular-svg-icons/faRoute";
import { faFileUpload as farFileUpload } from "@fortawesome/pro-regular-svg-icons/faFileUpload";
import { faComment as farComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faEye as farEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faEyeSlash as farEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faSquareList } from "@fortawesome/pro-regular-svg-icons/faSquareList";
import { faCalendarTime } from "@fortawesome/pro-regular-svg-icons/faCalendarTime";
import { faBringFront } from "@fortawesome/pro-regular-svg-icons/faBringFront";
import { faSendBack } from "@fortawesome/pro-regular-svg-icons/faSendBack";
import { faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-regular-svg-icons/faArrowUpRightAndArrowDownLeftFromCenter";
import { faLayerPlus } from "@fortawesome/pro-regular-svg-icons/faLayerPlus";
import { faFileImport as faImportFile } from "@fortawesome/pro-regular-svg-icons/faFileImport";
import { faArrowPointer as farArrowPointer } from "@fortawesome/pro-regular-svg-icons/faArrowPointer";
import { faPersonWalking as farPersonWalking } from "@fortawesome/pro-regular-svg-icons/faPersonWalking";
import { faDoorOpen as farDoorOpen } from "@fortawesome/pro-regular-svg-icons/faDoorOpen";
import { faRulerCombined as farRulerCombined } from "@fortawesome/pro-regular-svg-icons/faRulerCombined";
import { faTally as farTally } from "@fortawesome/pro-regular-svg-icons/faTally";
import { faLock as farLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faLockOpen as farLockOpen } from "@fortawesome/pro-regular-svg-icons/faLockOpen";
import { faMagnet as farMagnet } from "@fortawesome/pro-regular-svg-icons/faMagnet";

// pro-light-svg-icons
import { faTag as falTag } from "@fortawesome/pro-light-svg-icons/faTag";
import { faTriangleExclamation as falTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
import { faXmark as falXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faEllipsis as falEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";
import { faClock as falClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faText as falText } from "@fortawesome/pro-light-svg-icons/faText";
import { faList as falList } from "@fortawesome/pro-light-svg-icons/faList";
import { faHashtag as falHashtag } from "@fortawesome/pro-light-svg-icons/faHashtag";
import { faCalendar as falCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar";
import { faCheck as falCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons/faStar";
import { faExpand as falExpand } from "@fortawesome/pro-light-svg-icons/faExpand";
import { faTrashUndo as falTrashUndo } from "@fortawesome/pro-light-svg-icons/faTrashUndo";
import { faFileUpload as falFileUpload } from "@fortawesome/pro-light-svg-icons/faFileUpload";
import { faRotateRight as falRotateRight } from "@fortawesome/pro-light-svg-icons/faRotateRight";
import { faLock as falLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { faUpload as falUpload } from "@fortawesome/pro-light-svg-icons/faUpload";
import { faDownload as falDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faFile as falFile } from "@fortawesome/pro-light-svg-icons/faFile";
import { faTimer as falTimer } from "@fortawesome/pro-light-svg-icons/faTimer";
import { faImage as falImage } from "@fortawesome/pro-light-svg-icons/faImage";
import { faLink as falLink } from "@fortawesome/pro-light-svg-icons/faLink";

// pro-duotone-svg-icons
import { faSort as fadSort } from "@fortawesome/pro-duotone-svg-icons/faSort";
import { faEnvelopeDot as fadEnvelopeDot } from "@fortawesome/pro-duotone-svg-icons/faEnvelopeDot";
import { faEnvelopeCircleCheck as fadEnvelopeCircleCheck } from "@fortawesome/pro-duotone-svg-icons/faEnvelopeCircleCheck";

// Combined compound svgs as icons
import { LineNodesIcon as faLineNodes } from "./compound-svgs/line-nodes";
import { AreaSelectIcon as faAreaSelect } from "./compound-svgs/area-select";

library.add(
    falText,
    falList,
    falHashtag,
    falCalendar,
    falCheck,
    farUsers,
    faCircleCheck, // replaced faCheckCircle
    faFilter,
    farFilter,
    faArrowUpRightFromSquare, // replaced faExternalLinkAlt
    faMountain,
    faFileArrowDown, // replaced faFileDownload
    farFileArrowUp,
    farFileArrowDown, // replaced farFileDownload
    faBars,
    faFolder,
    faFolderOpen,
    faHouseChimney, // replaced faHome
    farHouseChimney, // replaced farHome
    faMap,
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faLayerGroup,
    faLayerPlus,
    faFileImport,
    faImportFile,
    faSquare,
    faEllipsis, // replaced faEllipsisH
    faEllipsisVertical, // replaced faEllipsisV
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faHorizontalRule,
    faEye,
    faEyeSlash,
    faLink,
    faPlus,
    fasPlus,
    faRotateLeft, // replaced faUndo
    faRotateRight, // replaced faRedo
    falRotateRight,
    fasPencil,
    faTable,
    faXmark, // replaced faTimes
    falXmark, // replaced falTimes
    faCircleInfo, // replaced faInfoCircle
    farMap,
    faCirclePlus, // replaced faPlusCircle
    farCirclePlus,
    faUserTie,
    faUsers,
    faChevronsUp, // replaced faChevronsDoubleUp
    faChevronsDown, // replaced faChevronsDoubleDown
    faCircleQuestion, // replaced faQuestionCircle
    farCircleInfo, // replaced farInfoCircle
    faCheck,
    fasCheck,
    faSpinner,
    faClock,
    falClock,
    faCircleNotch,
    faStar,
    farUsers,
    faTrash,
    faPenRuler, // replaced faPencilRuler
    faMagnifyingGlass, // replaced faSearch
    faCamera,
    faPenToSquare, // replaced faEdit
    faChevronRight,
    faChevronLeft,
    faGear, // replaced faCog
    fasSort,
    faArrowsUpDownLeftRight, // replaced faArrows
    faFolderPlus,
    fasStar,
    faExpand,
    farRulerTriangle,
    faVectorSquare,
    farSort,
    farMessageLines, // replaced faCommentAltLines
    faCalendar,
    farCalendar,
    faCalendarDays, // replaced faCalendarAlt
    faMinus,
    faObjectUngroup,
    faPrint,
    faLinkSlash, // replaced faUnlink
    faCrosshairs,
    farXmark, // replaced farTimes
    farRotateLeft, // replaced farUndo
    farRotateRight, // replaced farRedo
    faCopy,
    faCube,
    faTag,
    falTag,
    faSortDown,
    farMinus,
    farTag,
    faPalette,
    faClipboard,
    farArrowUpRightFromSquare, // replaced farExternalLinkAlt
    faSortUp,
    faCircle,
    faShareNodes, // replaced faShareAlt
    faKey,
    faFilePlus,
    faListUl,
    faChevronsLeft, // replaced faChevronDoubleLeft
    falTriangleExclamation, // replaced falExclamationTriangle
    faScreenUsers, // replaced faUsersClass
    faUserPlus,
    faUser,
    faEnvelope,
    farScreenUsers, // replaced farUsersClass
    faBug,
    faTriangleExclamation, // replaced faExclamationTriangle
    falEllipsis, // replaced falEllipsisH
    farCrown,
    faBooks,
    faFile,
    fasLock,
    fasLockOpen,
    farLock,
    farLockOpen,
    faCircleExclamation, // replaced faExclamationCircle
    faUpload,
    fasKey,
    faCircleXmark,
    faImage,
    faFolders,
    farCircleXmark,
    fasLayerGroup,
    faPallet,
    farCircleXmark,
    falStar,
    faFolderClosed,
    fasFolders,
    fasCube,
    faFunction,
    faDatabase,
    farCircle,
    faDiamond,
    faText,
    faTableCellsLarge,
    fasTableCellsLarge,
    faList,
    faAperture,
    faBold,
    fadSort,
    falExpand,
    faCompass,
    fasCircleInfo,
    farPencil,
    fasRulerTriangle,
    fasMessageLines,
    farCalendarDays,
    faEarthEurope,
    faRulerHorizontal,
    faPersonWalking,
    faDoorOpen,
    faRulerCombined,
    faTally,
    farDrawPolygon,
    farScribble,
    faUserSlash,
    faPassport,
    farGear,
    faTrashUndo,
    falFileUpload,
    fasFileUpload,
    falTrashUndo,
    faLocationDot,
    faLocationCrosshairs,
    farPhoneFlip,
    fasUser,
    faClouds,
    falClouds,
    faCircleDot,
    faAngle,
    fasArrowPointer,
    farCircleNotch,
    faLockKeyhole,
    faFileSpreadsheet,
    fasCopy,
    fasShareNodes,
    faUp,
    faDown,
    faLeft,
    faRight,
    faUpDownLeftRight,
    falLock,
    faUnlock,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faFilePdf,
    faPencilSlash,
    fasFileInvoice,
    fasCreditCard,
    fasFileInvoiceDollar,
    far1,
    far2,
    far3,
    far4,
    far5,
    farTelescope,
    farThoughtBubble,
    farRugbyBall,
    farRoad,
    farPartyHorn,
    farPanorama,
    farRing,
    farThumbtack,
    fas1,
    fas2,
    fas3,
    fas4,
    fas5,
    fasTelescope,
    fasThoughtBubble,
    fasRugbyBall,
    fasRoad,
    fasPartyHorn,
    fasPanorama,
    fasRing,
    fasThumbtack,
    fasMagnifyingGlass,
    farCalendarRange,
    fasCalendarRange,
    farCheckCircle,
    fasArrowLeft,
    fasChevronRight,
    farUserLock,
    fadEnvelopeDot,
    fadEnvelopeCircleCheck,
    farCircleUp,
    faArrowRight,
    faRuler,
    farCircleQuestion,
    faArrowsRotate,
    farCircleArrowUp,
    falUpload,
    farFolder,
    falDownload,
    farDownload,
    fasDownload,
    falFile,
    farBan,
    fasGripDots,
    farChevronDown,
    farPenToSquare,
    farUpload,
    falTimer,
    farRoute,
    fasCar,
    fasBicycle,
    fasClock,
    falImage,
    falLink,
    farFileUpload,
    farComment,
    farEye,
    farEyeSlash,
    faThumbsUp,
    fasLocationDot,
    fasBell,
    faSquareList,
    faCalendarTime,
    faSendBack,
    faBringFront,
    faArrowUpRightAndArrowDownLeftFromCenter,
    farMagnet,
    farArrowPointer,
    farPersonWalking,
    farDoorOpen,
    farRulerCombined,
    farTally,
    faLineNodes,
    faAreaSelect,
    farMessagePlus
);
