/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { AuthenticatedUser } from "@iventis/domain-model/model/authenticatedUser";
import { toast, ToastType } from "@iventis/toasts";
import { Content } from "@iventis/translations";
import { Node } from "@iventis/domain-model/model/node";
import { UserScope } from "@iventis/domain-model/model/userScope";
import { UserPreferences } from "@iventis/domain-model/model/userPreferences";
import { Project } from "@iventis/domain-model/model/project";
import { WaitingForEvent, LoadingEvent } from "@iventis/types/loading.types";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { EMPTY_GUID, IVENTIS_HELP_FORM } from "@iventis/utilities";
import { isRemoteSubscriptionInUrl, readSubscriptionFromUrl, isUrlSubscriptionEqual, ProjectSubscriptionCancellationUpdate } from "@iventis/subscriptions";
import { ProjectUsage } from "@iventis/domain-model/model/projectUsage";
import { ProjectUserUsage } from "@iventis/domain-model/model/projectUserUsage";
import parseISO from "date-fns/parseISO";
import { permissionsApi, libraryApi, multipleAssetUrlGetter, assetsApi, iventisApi, getApiBaseUrlSpeficicSubdomain, getIventisEnvironmentData } from "@iventis/api/src/api";
import { removeWaitingForByEventName } from "@iventis/api/src/state-helpers";
import { translate } from "@iventis/translations/translation";
import { AssetsPostRequest, MicroService } from "@iventis/api-helpers";
import { AssetManagement } from "@iventis/domain-model/model/assetManagement";
import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { setMonitoringUser } from "@iventis/observability-and-monitoring";
import { UsagePropertyUpdate } from "./project-limiter-hook";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _hsq: any[];
    }
}

interface LoginErrorResponse {
    message: string;
}

export interface ProjectImage {
    imageDataUrl: string;
    fileName: string;
}

export interface ProjectWithImages extends Project {
    imageUrl: string;
    logoUrl: string;
    imageFileName: string;
    logoFileName: string;
}

export interface AuthState {
    user: AuthenticatedUser;
    projects: ProjectWithImages[];
    // Loading user must be separate from the rest of the loading states (waitingFor array) as the App Router should only depend on loading User
    loadingUser: boolean;
    waitingFor: WaitingForEvent[];
    loginError: string;
    ssoProviderName: string;
    loadingProjectId: string;
    projectApplications: Node[];
    applicationsUpdating: ApplicationName[];
    userPreferences: UserPreferences;
    projectsLoaded: boolean;
    projectSubscription: ProjectSubscription;
    subscriptionPlan: SubscriptionPlan;
    projectUsage: ProjectUsage;
    projectUserUsage: ProjectUserUsage;
    generatingUserIcon: boolean;
    doesUserHaveProfileImage: boolean;
}

/** Hide the toasts about project subscription cancellation after 10 seconds */
const AUTO_HIDE_NOTIFICATION_CANCELLATION = 10000;

export const getProjectUsage = (projectId: string) => permissionsApi.get<ProjectUsage>(`project_usages/${projectId}`);

export const initialState: AuthState = {
    user: null,
    loadingUser: true,
    waitingFor: [],
    loginError: null,
    projects: [],
    ssoProviderName: null,
    loadingProjectId: null,
    projectApplications: null,
    applicationsUpdating: [],
    userPreferences: {
        showDrawingToolsHelp: false,
        showLayerTour: false,
        showMapTour: false,
        showAddAnotherObjectToLayer: true,
    },
    projectsLoaded: false,
    projectSubscription: undefined,
    subscriptionPlan: undefined,
    projectUsage: undefined,
    projectUserUsage: undefined,
    generatingUserIcon: false,
    doesUserHaveProfileImage: false,
};

export const getSsoProvider = createAsyncThunk<string>("auth/getSsoProvider", async () => {
    const response = await permissionsApi.get("/authentication/sso-provider");
    return response.data;
});

export const login = createAsyncThunk<AuthenticatedUser, { email: string; password: string }, { rejectValue: LoginErrorResponse }>(
    "auth/login",
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await permissionsApi.post("/authentication", {
                email: credentials.email,
                password: credentials.password,
                stayLoggedIn: false,
            });
            return response.data;
        } catch (err) {
            const error: AxiosError<LoginErrorResponse> = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    const response = await permissionsApi.delete(`/authentication`);
    const { subDomain } = getIventisEnvironmentData();

    // If on sub domain then also attempt to log out of main domain
    if (subDomain != null && subDomain !== "") {
        const baseApi = getApiBaseUrlSpeficicSubdomain(MicroService.PERMISSIONS, null);
        await iventisApi.delete(`${baseApi}authentication`).catch(() => {
            // do nothing with error
        });
    }
    return response.data;
});

const getProjectSubscription = async (project: Project) => {
    const { data: projectSubscription } = await permissionsApi.get<ProjectSubscription>(`projects/${project.id}/subscriptions/${project.projectSubscriptionId}`);

    // Convert to proper JS date object
    if (projectSubscription.renewalDate) {
        projectSubscription.renewalDate = parseISO(projectSubscription.renewalDate.toString());
    }

    // Convert to proper JS date object
    if (projectSubscription.committedUntil) {
        projectSubscription.committedUntil = parseISO(projectSubscription.committedUntil.toString());
    }

    return projectSubscription;
};

export const getCurrentUser = createAsyncThunk<{
    user: AuthenticatedUser;
    projectSubscription?: ProjectSubscription;
    subscriptionPlan?: SubscriptionPlan;
    projectUsage?: ProjectUsage;
    doesUserHaveProfileImage: boolean;
}>("auth/getCurrentUser", async (_, { rejectWithValue }) => {
    try {
        const { data: user } = await permissionsApi.get<AuthenticatedUser>("/authentication/user");
        // Identify the user in hubspot
        // eslint-disable-next-line no-underscore-dangle, no-multi-assign
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
            "identify",
            {
                email: user.emailAddress,
            },
        ]);

        let doesUserHaveProfileImage: boolean;
        try {
            await assetsApi.get<Asset>(`/assets/${user.userId}`, { suppressToasts: true });
            doesUserHaveProfileImage = true;
        } catch {
            doesUserHaveProfileImage = false;
        }

        if (user.projectId == null) {
            return { user, doesUserHaveProfileImage };
        }
        if (user.projectId === EMPTY_GUID) {
            return { user, projectSubscription: null, subscriptionPlan: null, doesUserHaveProfileImage };
        }
        const { data: project } = await permissionsApi.get<Project>(`projects/${user.projectId}`);
        setMonitoringUser(
            { firstName: user.firstName, lastName: user.lastName, email: user.emailAddress, id: user.userId, externalUser: user.userScope === UserScope.External },
            { id: project.id, name: project.name }
        );

        // eslint-disable-next-line prefer-const
        let [projectSubscription, { data: subscriptionPlan }, { data: projectUsage }] = await Promise.all([
            getProjectSubscription(project),
            permissionsApi.get<SubscriptionPlan>(`projects/${user.projectId}/plans`),
            ...(user.userScope !== UserScope.External ? [getProjectUsage(user.projectId)] : [{ data: undefined }]),
        ]);

        if (isRemoteSubscriptionInUrl()) {
            const urlProjectSubscription = readSubscriptionFromUrl();
            if (!isUrlSubscriptionEqual(urlProjectSubscription, projectSubscription)) {
                let reqs = 0;
                let success = false;
                while (reqs < 5 && !success) {
                    const { data: newProject } = await permissionsApi.get<Project>(`projects/${user.projectId}`);
                    projectSubscription = await getProjectSubscription(newProject);
                    if (!isUrlSubscriptionEqual(urlProjectSubscription, projectSubscription)) {
                        reqs += 1;
                    } else {
                        success = true;
                        break;
                    }
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }
                if (!success) {
                    throw new Error("Failed to find correct subscription");
                }
                // If the plan doesn't match that of the new project subscriptions' planId, then fetch the public ones and find the matching one
                if (subscriptionPlan.id !== projectSubscription.subscriptionPlanId) {
                    const publicPlans = (await permissionsApi.get<SubscriptionPlan[]>("public-subscription-plans")).data;
                    subscriptionPlan = Object.values(publicPlans).find((p) => p.id === projectSubscription.subscriptionPlanId);
                }
            }
        }

        return { user, projectSubscription, subscriptionPlan, projectUsage, doesUserHaveProfileImage };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return rejectWithValue(error);
    }
});

export const getProjectBillingData = createAsyncThunk<
    { projectSubscription: ProjectSubscription; subscriptionPlan: SubscriptionPlan; projectUsage: ProjectUsage },
    string,
    { rejectValue: { message: string; code: number }; state: { auth: AuthState } }
>("auth/getProjectSubscrition", async (projectId, { rejectWithValue, getState }) => {
    try {
        let project: Project = getState().auth.projects?.find((p) => p.id === projectId);
        if (project == null) {
            // If we don't have the project in state yet, fetch it from the permissions service
            const { data } = await permissionsApi.get<Project>(`projects/${projectId}`);
            project = data;
        }
        const [projectSubscription, { data: subscriptionPlan }, { data: projectUsage }] = await Promise.all([
            getProjectSubscription(project),
            permissionsApi.get<SubscriptionPlan>(`projects/${projectId}/plans`),
            getProjectUsage(projectId),
        ]);
        return { projectSubscription, subscriptionPlan, projectUsage };
    } catch (err) {
        const error: AxiosError<string> = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue({
            message: error.response.data,
            code: error.response.status,
        });
    }
});

export const getProjects = createAsyncThunk<ProjectWithImages[], { signal?: AbortSignal }>("auth/getProjects", async ({ signal }) => {
    const response = await permissionsApi.get<Project[]>(`/projects/`, { signal });
    const projects = response.data;
    const imageIds = projects.map((p) => p.imageId).filter((id) => id !== undefined);
    const logoIds = projects.map((p) => p.logoId).filter((id) => id !== undefined);
    const imageAssets = await multipleAssetUrlGetter([...imageIds, ...logoIds]);

    return projects.map((project) => {
        const imageAsset = imageAssets.find((asset) => asset.id === project.imageId);
        const logoAsset = imageAssets.find((asset) => asset.id === project.logoId);

        return {
            ...project,
            imageUrl: imageAsset?.url,
            imageFileName: imageAsset?.name,
            logoUrl: logoAsset?.url,
            logoFileName: logoAsset?.name,
        };
    });
});

export const getProjectApplications = createAsyncThunk<Node[], { signal?: AbortSignal }>("auth/getProjectApplications", async ({ signal }) => {
    const response = await libraryApi.get("/applications", { signal });
    return response.data;
});

export const updateProjectDetails = createAsyncThunk<Project, { signal?: AbortSignal; name?: string }, { state }>(
    "auth/setProjectDetails",
    async ({ signal, name }, { getState, rejectWithValue }) => {
        const { projectId } = getState().auth.user;
        try {
            const response = await permissionsApi.patch<Project>(`/projects/${projectId}`, { name }, { signal });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const uploadProjectLogo = createAsyncThunk<Project, { signal?: AbortSignal; image: ProjectImage }, { state: { auth: AuthState } }>(
    "auth/setProjectLogo",
    async ({ signal, image }, { getState, rejectWithValue }) => {
        const { projectId } = getState().auth.user;
        try {
            const response = await permissionsApi.patch<Project>(`/projects/${projectId}`, { logo: image }, { signal });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const uploadProjectImage = createAsyncThunk<Project, { signal?: AbortSignal; image: ProjectImage }, { state }>(
    "auth/setProjectImage",
    async ({ signal, image }, { getState, rejectWithValue }) => {
        const { projectId } = getState().auth.user;
        try {
            const response = await permissionsApi.patch<Project>(`/projects/${projectId}`, { image }, { signal });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProjectImage = createAsyncThunk<unknown, { signal?: AbortSignal }, { state }>("auth/deleteProjectLogo", async ({ signal }, { getState, rejectWithValue }) => {
    const { projectId } = getState().auth.user;
    try {
        const response = await permissionsApi.patch(`/projects/${projectId}`, { image: null }, { signal });
        return response.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const deleteProjectLogo = createAsyncThunk<unknown, { signal?: AbortSignal }, { state }>("auth/deleteProjectImage", async ({ signal }, { getState, rejectWithValue }) => {
    const { projectId } = getState().auth.user;
    try {
        const response = await permissionsApi.patch(`/projects/${projectId}`, { logo: null }, { signal });
        return response.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getUserPreferences = createAsyncThunk<UserPreferences, { signal?: AbortSignal }, { state }>("auth/getUserPreferences", async (_, { getState, rejectWithValue }) => {
    try {
        // If the user is an external user just set the user preferences to be the initial (all false)
        const { userScope } = getState().auth.user;
        if (userScope === UserScope.External) {
            return initialState.userPreferences;
        }
        const response = await permissionsApi.get<UserPreferences>(`users/preferences`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateUserPreferences = createAsyncThunk<UserPreferences, Partial<UserPreferences>, { state: { auth: AuthState } }>(
    "auth/updateUserPreferences",
    async (newUserPreferences, { rejectWithValue, getState }) => {
        const { userPreferences: oldUserPreferences } = getState().auth;
        const userPreferences = { ...oldUserPreferences };
        // Update the user preferences with the new values
        Object.entries(newUserPreferences).forEach(([key, value]) => {
            userPreferences[key] = value;
        });
        try {
            const response = await permissionsApi.put<UserPreferences>(`users/preferences`, userPreferences);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateProjectSubscription = createAsyncThunk<
    ProjectSubscriptionCancellationUpdate,
    { subscriptionUpdate: ProjectSubscriptionCancellationUpdate; projectId: string; projectSubscriptionId: string },
    { state: { auth: AuthState } }
>("auth/updateProjectSubscription", async ({ subscriptionUpdate, projectId, projectSubscriptionId }, { rejectWithValue }) => {
    try {
        await permissionsApi.patch<void>(`projects/${projectId}/subscriptions/${projectSubscriptionId}`, subscriptionUpdate);
        return subscriptionUpdate;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const updateSubscription = createAsyncThunk<
    ProjectSubscription,
    { projectSubscription: ProjectSubscription; subscriptionPlan: SubscriptionPlan },
    { state: { auth: AuthState } }
>("auth/updateSubscription", async ({ projectSubscription, subscriptionPlan }, { rejectWithValue }) => {
    try {
        if (projectSubscription.subscriptionPlanId !== subscriptionPlan.id) {
            throw new Error("Subscription plan does not match the plan referenced in the project subscription");
        }
        const response = await permissionsApi.put<ProjectSubscription>(`projects/${projectSubscription.projectId}/subscriptions/${projectSubscription.id}`, projectSubscription);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const uploadUserIcon = createAsyncThunk<Asset, { dataUrl: string; user: AuthenticatedUser }>("auth/uploadUserIcon", async ({ dataUrl, user }, { rejectWithValue }) => {
    try {
        const { data: asset } = await assetsApi.post<Asset, AxiosResponse<Asset>, AssetsPostRequest>(
            "assets",
            {
                name: `${user.firstName ?? user.emailAddress}'s profile image`,
                dataUrl,
                type: AssetType.ProfileImage,
                tags: [],
                metaData: undefined,
                management: AssetManagement.Internal,
                id: user.userId,
            },
            { params: { height: "44px", width: "44px" } }
        );
        return asset;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        removeUser(state) {
            state.user = null;
        },
        addUser(state, action: PayloadAction<AuthenticatedUser>) {
            state.user = action.payload;
        },
        removeProject(state) {
            state.user.projectId = null;
        },
        removeProjectApplications(state) {
            state.projectApplications = null;
        },
        setApplicationUpdating(state, action: PayloadAction<{ isUpdating: boolean; application: ApplicationName }>) {
            if (action.payload.isUpdating) {
                state.applicationsUpdating = [...state.applicationsUpdating, action.payload.application];
            } else {
                state.applicationsUpdating = state.applicationsUpdating.filter((a) => a !== action.payload.application);
            }
        },
        setProjectId(state, action: PayloadAction<{ projectId: string }>) {
            state.user.projectId = action.payload.projectId;
        },
        setUsage(state, action: PayloadAction<{ usage: UsagePropertyUpdate; adjustment: number }>) {
            switch (action.payload.usage) {
                case "adjustTeamMaps":
                    state.projectUsage.teamMaps += action.payload.adjustment;
                    break;
                case "adjustUsers":
                    state.projectUsage.users += action.payload.adjustment;
                    break;
                default:
                    throw new Error("Usage type not supported");
            }
        },
        generateUserIcon(state) {
            state.generatingUserIcon = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSsoProvider.fulfilled, (state, action: PayloadAction<string>) => {
                state.ssoProviderName = action.payload;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<AuthenticatedUser>) => {
                state.loginError = null;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action: PayloadAction<LoginErrorResponse, string, { arg: { email: string } }>) => {
                if (action.type === "auth/login/rejected" && action.payload?.toString() === "User email is not verified, please verify your email to login") {
                    const encodedEmail = encodeURIComponent(action.meta?.arg?.email);
                    window.location.assign(`/verify-email?email=${encodedEmail}`);
                }
                // If error message has been produced on the back end, add to loginError state.
                if (action.payload) {
                    state.loginError = action.payload.message;
                }
                state.loadingUser = false;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            })
            .addCase(getCurrentUser.pending, (state) => {
                state.loadingUser = true;
            })
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.loadingUser = false;
                state.projectSubscription = action.payload.projectSubscription;
                state.subscriptionPlan = action.payload.subscriptionPlan;
                state.projectUsage = action.payload.projectUsage;
                state.doesUserHaveProfileImage = action.payload.doesUserHaveProfileImage;
            })
            .addCase(getCurrentUser.rejected, (state) => {
                state.loadingUser = false;
            })
            .addCase(getProjects.pending, (state) => {
                state.waitingFor.push({ id: null, eventName: LoadingEvent.GET_PROJECTS });
            })
            .addCase(getProjects.fulfilled, (state, action: PayloadAction<ProjectWithImages[]>) => {
                state.projects = action.payload;
                state.waitingFor = removeWaitingForByEventName(LoadingEvent.GET_PROJECTS, state.waitingFor);
                state.projectsLoaded = true;
            })
            .addCase(getProjects.rejected, (state) => {
                state.waitingFor = removeWaitingForByEventName(LoadingEvent.GET_PROJECTS, state.waitingFor);
            })
            .addCase(updateProjectDetails.fulfilled, (state, action) => {
                const project = state.projects.find((p) => p.id === state.user.projectId);
                project.name = action.meta.arg.name;
            })
            .addCase(uploadProjectLogo.fulfilled, (state, action) => {
                const project = state.projects.find((p) => p.id === state.user.projectId);
                project.logoId = action.payload.id;
                project.logoUrl = action.meta.arg.image.imageDataUrl;
                project.logoFileName = action.meta.arg.image.fileName;
            })

            .addCase(deleteProjectLogo.fulfilled, (state) => {
                const project = state.projects.find((p) => p.id === state.user.projectId);
                project.logoId = undefined;
                project.logoUrl = undefined;
                project.logoFileName = undefined;
            })
            .addCase(uploadProjectImage.fulfilled, (state, action) => {
                const project = state.projects.find((p) => p.id === state.user.projectId);
                project.imageId = action.payload.id;
                project.imageUrl = action.meta.arg.image.imageDataUrl;
                project.imageFileName = action.meta.arg.image.fileName;
            })
            .addCase(deleteProjectImage.fulfilled, (state) => {
                const project = state.projects.find((p) => p.id === state.user.projectId);
                project.imageId = undefined;
                project.imageUrl = undefined;
                project.imageFileName = undefined;
            })
            .addCase(getProjectApplications.fulfilled, (state, action: PayloadAction<Node[]>) => {
                state.projectApplications = action.payload;
            })
            .addCase(getUserPreferences.fulfilled, (state, action) => {
                state.userPreferences = action.payload;
            })
            .addCase(updateSubscription.fulfilled, (state, action) => {
                state.projectSubscription = action.payload;
                state.subscriptionPlan = action.meta.arg.subscriptionPlan;
            })
            .addCase(updateUserPreferences.fulfilled, (state, action) => {
                state.userPreferences = action.payload;
            })
            .addCase(updateProjectSubscription.fulfilled, (state, action) => {
                state.projectSubscription.cancelAtCommittedUntil = action.payload.cancelAtCommittedUntil;
                if (action.payload.cancelAtCommittedUntil) {
                    const expiryDate = state.projectSubscription.committedUntil ?? state.projectSubscription.renewalDate;
                    toast.success(
                        {
                            type: ToastType.BASIC,
                            props: {
                                message: `${translate(Content.subscriptionplan3.cancellation.success)} ${expiryDate.toLocaleDateString()}`,
                                icon: "circle-check",
                            },
                        },
                        { autoHideDuration: AUTO_HIDE_NOTIFICATION_CANCELLATION }
                    );
                } else {
                    toast.success(
                        {
                            type: ToastType.BASIC,
                            props: { message: translate(Content.subscriptionplan3.cancellation.successReactivate), icon: "circle-check" },
                        },
                        { autoHideDuration: AUTO_HIDE_NOTIFICATION_CANCELLATION }
                    );
                }
            })
            .addCase(updateProjectSubscription.rejected, (_, action) => {
                const message = action.meta.arg.subscriptionUpdate.cancelAtCommittedUntil
                    ? translate(Content.subscriptionplan3.cancellation.error)
                    : translate(Content.subscriptionplan3.cancellation.errorReactivate);
                toast.error(
                    {
                        type: ToastType.BUTTON,
                        props: {
                            message,
                            icon: "circle-exclamation",
                            buttonLabel: translate(Content.subscriptionplan3.cancellation.contactUs),
                            onClick: () => window.open(IVENTIS_HELP_FORM),
                        },
                    },
                    { autoHideDuration: AUTO_HIDE_NOTIFICATION_CANCELLATION }
                );
            })
            .addCase(getProjectBillingData.fulfilled, (state, action) => {
                state.projectSubscription = action.payload.projectSubscription;
                state.subscriptionPlan = action.payload.subscriptionPlan;
                state.projectUsage = action.payload.projectUsage;
            })
            .addCase(uploadUserIcon.fulfilled, (state) => {
                state.generatingUserIcon = false;
                state.doesUserHaveProfileImage = true;
            })
            .addCase(uploadUserIcon.rejected, (state) => {
                state.generatingUserIcon = false;
            });
    },
});

export const { removeUser, addUser, removeProject, setProjectId } = authSlice.actions;
export default authSlice.reducer;
