import { Theme } from "@emotion/react";
import { Direction, PaletteMode, ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { fontSizes } from "./atomic-rules";
import { defaultIGridColours } from "./constants";

// Width of all intercative icons
export const iconButtonWidth = 44;

export const main = {
    primaryColors: {
        focus: "#D1005E", // Dark Pink
        altFocusText: "#494eaa", // Purpley-blue
        altFocusBorder: "#d6d6ec", // Light purpley-blue
        focusAccent: "#FF2a8B", // Accent Pink
        subdued50: "#30325E", // Mid Blue
        subdued70: "#26273F", // Dark Blue
        subduedMono: "#484848", // Dark Grey
        blue50: "rgba(118, 192, 227, 0.5)", // Mid blue
        blue25: "#DAF2FE", // Light blue
    },
    shades: {
        one: "#1A1A1A",
        two: "#757575",
        three: "#CCCCCC",
        four: "#EEEEEE",
        five: "#FFFFFF",
        libraryHover: "#F6F4F4",
        darkBorder: "#b6b6b6",
        lightBorder: "#e9e9e9",
    },
    secondaryColors: {
        warm: "#F0EDED", // Warm grey
        light20: "#F6F4F4", // Lighter slightly warm grey
        light30: "#F6F6F6", // Lighter grey
        lightGrey: "#e6e6e6", // light grey
        grey: "#d0d0d0", // Middle of the road grey
        light50: "#FFF6FA", // Light Pink
        dark: "#F6CCDF", // Dark Pink at 20%
        blank: "#FFFFFF", // White
        confirm: "#008000", // Green
        cancel: "#FF0000", // Red
        darkAccent: "#48485D", // Dark Blue-Grey
        mediumAccent: "#515265", // Medium blue-grey
        lightAccent: "#676878", // Light Blue-Grey
        lighterAccent: "#f6f3f4", // Lighter Blue-Grey
        error: "#FF0000", // Red
        selection: "#dcddf9",
        lightError: "#FFEBEF", // Light Pink
        accept: "#27CCA3", // Light Green
        deny: "#FF685F", // Light Red
        warning: "#DED329",
    },
    tertiaryColors: {
        primary: "#454aa8",
        primaryBackground: "#eff0fe",
        headerBackground: "#dfdfdf",
        highlightBackground: "#dcdefa",
        highlightBackgroundHover: "#9ea4f1",
        selectionBackgroundColour: "#cfd1f7 !important", // important is used to override the session style in DCAS
    },
    typographyColors: {
        core: "#1A1A1A", // Black
        lessSubdued: "#484848", // Dark Grey
        subdued: "#757575", // Grey
        moreSubdued: "#9394a0", // Lighter grey,
        evenMoreSubdued: "#b7b7b7", // Even Lighter grey,
        blank: "#FFFFFF", // White
        primary: "#d1005e", // DARK PINK
        linkOnError: "#EB0C00", // PINKISH RED
    },
    otherColors: {
        separatorDark: "#605b6033",
        separatorLight: "rgba(255, 255, 255, 0.4)",
        hover: "rgba(246, 244, 244, 0.75)",
        inputBorder: "rgb(117, 117, 117)",
        dataDrivenIcon: "#404167",
        dataDrivenHover: "#eff0fe",
        dataDrivenBackground: "rgba(48, 50, 94, 0.14)",
        dataDrivenBackgroundDisabled: "rgba(48, 50, 94, 0.4)",
        highlightChipHover: "#c7cbf7",
        priceColour: "#63c24f",
        warningBackground: "#FFF66C",
    },
    shimmer: {
        background: "#dddbdd",
        pulse: "rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0)",
        pulseStrong: "rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0)",
    },
    sizes: {
        iconButtonWidth: `${iconButtonWidth}px`,
    },
    summaryColours: defaultIGridColours,
    toastColours: {
        success: "#6ec34d",
        successBorder: "#4c8934",
        error: "#ff6666",
        errorBorder: "#e7493f",
        warning: "#ffa366",
        warningBorder: "#ded329",
        info: "#66c2ff",
        infoBorder: "#2292dd",
    },
    scrollbar: {
        thumbOne: "#d8d8d8",
        thumbOneHover: "#b6b6b6",
        thumbTwo: "#e9e9e9",
        thumbTwoHover: "#dfdfdf",
    },
};

export const dark: Theme = {
    primaryColors: {
        focus: "#FF1493",
        focusAccent: "#002171",
        altFocusText: "#494eaa",
        altFocusBorder: "#d6d6ec",
        subdued70: "#AABEDE",
        subduedMono: "#CEDAEC",
        subdued50: "#E7ECF5",
        blue50: "rgba(118, 192, 227, 0.5)",
        blue25: "#DAF2FE",
    },
    shades: {
        one: "#FFFF00",
        two: "#EEEEEE",
        three: "#CCCCCC",
        four: "#757575",
        five: "#1A1A1A",
        libraryHover: "#F6F4F4",
        darkBorder: "#b6b6b6",
        lightBorder: "#e9e9e9",
    },
    secondaryColors: {
        warm: "#F0EDED",
        light20: "#F6F4F4",
        light30: "#F6F6F6", // Lighter grey
        lightGrey: "#e6e6e6",
        light50: "#FFF6FA",
        grey: "#d0d0d0", // Middle of the road grey
        dark: "#000000",
        blank: "#FFFFFF",
        confirm: "#008000",
        selection: "#dcddf9",
        cancel: "#FF0000",
        darkAccent: "#48485D",
        mediumAccent: "#515265", // Medium blue-grey
        lightAccent: "#676878",
        lighterAccent: "#f6f3f4", // Lighter Blue-Grey
        error: "#FF0000",
        lightError: "#FFEBEF",
        accept: "#27CCA3",
        deny: "#FF685F",
        warning: "#DED329",
    },
    tertiaryColors: {
        primary: "#454aa8",
        primaryBackground: "#cfd1f7",
        headerBackground: "#dfdfdf",
        highlightBackground: "#dcdefa",
        highlightBackgroundHover: "#c7cbf7",
        selectionBackgroundColour: "#cfd1f7",
    },
    typographyColors: {
        core: "#FFFFFF",
        subdued: "#757575",
        moreSubdued: "#9394a0",
        evenMoreSubdued: "#b3b4c4",
        lessSubdued: "#B7B7B7",
        blank: "#1A1A1A",
        primary: "#d1005e",
        linkOnError: "#EB0C00", // PINKISH RED
    },
    otherColors: {
        separatorDark: "#605b6033",
        separatorLight: "rgba(255, 255, 255, 0.4)",
        hover: "rgba(246, 244, 244, 0.75)",
        inputBorder: "rgb(117, 117, 117)",
        dataDrivenIcon: "#404167",
        dataDrivenHover: "#eff0fe",
        dataDrivenBackground: "rgba(48, 50, 94, 0.14)",
        dataDrivenBackgroundDisabled: "rgba(48, 50, 94, 0.4)",
        highlightChipHover: "#c7cbf7",
        priceColour: "#63c24f",
        warningBackground: "#FFF66C",
    },
    sizes: {
        iconButtonWidth: `${iconButtonWidth}px`,
    },
    shimmer: main.shimmer,
    summaryColours: defaultIGridColours,
    toastColours: {
        success: "#6ec34d",
        successBorder: "#4c8934",
        error: "#ff6666",
        errorBorder: "#e7493f",
        warning: "#ffa366",
        warningBorder: "#ded329",
        info: "#66c2ff",
        infoBorder: "#76c0e3",
    },
    scrollbar: {
        thumbOne: "#e9e9e9",
        thumbOneHover: "#dfdfdf",
        thumbTwo: "#cccccc",
        thumbTwoHover: "#b6b6b6",
    },
};

export const POPOVER_VIEWPORT_MARGIN = "32px";

export const muiTheme = (theme: Theme, mode: PaletteMode, direction: Direction, overrides?: ThemeOptions) =>
    createTheme({
        direction,
        typography: {
            fontFamily: "SourceSansPro",
            allVariants: {
                textTransform: "none",
            },
        },
        palette: {
            mode,
            text: {
                primary: theme.shades.one,
                secondary: theme.shades.two,
                disabled: theme.shades.three,
            },
            primary: {
                light: theme.primaryColors.focusAccent,
                main: theme.primaryColors.focus,
                dark: theme.primaryColors.focus,
                contrastText: theme.shades.five,
            },
            secondary: {
                light: theme.secondaryColors.light50,
                main: theme.secondaryColors.warm,
                dark: theme.secondaryColors.light20,
                contrastText: theme.shades.five,
            },
            background: {
                paper: theme.shades.five,
                default: theme.shades.five,
            },
            error: { main: theme.toastColours.error },
            warning: { main: theme.toastColours.warning },
            info: { main: theme.toastColours.info },
            success: { main: theme.toastColours.success },
        },
        components: {
            // Place any style overrides here
            MuiButton: {
                styleOverrides: {
                    root: {
                        height: theme.sizes.iconButtonWidth,
                        fontSize: fontSizes.small,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": { backgroundColor: theme.shades.four, "&.Mui-focusVisible": { backgroundColor: theme.shades.four } },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: {
                        "&MuiPopover-paper": { maxHeight: `calc(100dvh - ${POPOVER_VIEWPORT_MARGIN})` },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        // Force no margin on chips, so they may be used in flex containers with the "gap" property
                        "&.MuiChip-root": {
                            marginRight: 0,
                        },
                    },
                },
            },
        },
        transitions: environmentTransitionSettings(),
        ...theme,
        ...overrides,
    });

const environmentTransitionSettings = () => {
    if ("Cypress" in window) {
        return { create: () => "none" };
    }
    return {};
};
