import { EventMessage } from "@iventis/utilities";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MachineTypes, machineEventTypes } from "../machines/map-machines.types";

export const MapEventTypes = {
    GET_USER_LOCATION: "GET_USER_LOCATION" as const,
    REFRESH_SOURCE_TILES: "REFRESH_SOURCE_TILES" as const,
    REFRESH_LOCAL_OBJECTS: "REFRESH_LOCAL_OBJECTS" as const,
    REFRESH_ASSET: "REFRESH_ASSET" as const,
};

export interface GetUserLocation {
    type: typeof MapEventTypes.GET_USER_LOCATION;
}

export interface RefreshMapTileSourceEvent {
    type: typeof MapEventTypes.REFRESH_SOURCE_TILES;
    payload: {
        sourceNames: string[];
    };
}

export interface RefreshLocalObjectsEvent {
    type: typeof MapEventTypes.REFRESH_LOCAL_OBJECTS;
}

export interface ZoomEvents {
    type: typeof machineEventTypes.zoomIn | typeof machineEventTypes.zoomOut;
}

export interface RefreshAssetEvent {
    type: typeof MapEventTypes.REFRESH_ASSET;
    payload: {
        assetId: string;
        thumbnailAssetId?: string;
        type: AssetType;
    };
}

export type MapEvents = GetUserLocation | RefreshMapTileSourceEvent | RefreshLocalObjectsEvent | ZoomEvents | RefreshAssetEvent;

type UsedMapEventTypes = Pick<MapEvents, "type">["type"];

export interface EventMessageMap extends EventMessage {
    type: MachineTypes | UsedMapEventTypes;
}
