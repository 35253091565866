import { css } from "@emotion/react";

export const mapPageMixin = ({ leftWidth, isMediumScreenSize }: { leftWidth: string; isMediumScreenSize: boolean }) => css`
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: ${isMediumScreenSize ? 0 : leftWidth} auto;
`;

export const sidebarHeaderMixin = css`
    height: 3.5rem;
`;

export const layerSectionMixin = css`
    height: 44px;
`;
