export enum MetricName {
    USER_SESSION = "USER_SESSION",
    MAP_LOADING = "MAP_LOADING",
    MAPBOX_LOADING = "MAPBOX_LOADING",
    PLAN_CREATED = "PLAN_CREATED",
    PLAN_LOADED = "PLAN_LOADED",
    PLAN_EXPORTED_PNG = "PLAN_EXPORTED_PNG",
    PLAN_EXPORTED_PDF = "PLAN_EXPORTED_PDF",
    MAP_OBJECTS_EXPORTED_GEOJSON = "MAP_OBJECTS_EXPORTED_GEOJSON",
    MAP_OBJECTS_IMPORTED_GEOJSON = "MAP_OBJECTS_IMPORTED_GEOJSON",
    MAP_OBJECTS_IMPORTED_KML = "MAP_OBJECTS_IMPORTED_KML",
    MAP_OBJECTS_IMPORTED_KMZ = "MAP_OBJECTS_IMPORTED_KMZ",
    AREA_LAYER_CREATED = "AREA_LAYER_CREATED",
    LINE_LAYER_CREATED = "LINE_LAYER_CREATED",
    POINT_LAYER_CREATED = "POINT_LAYER_CREATED",
    ICON_LAYER_CREATED = "ICON_LAYER_CREATED",
    MODEL_LAYER_CREATED = "MODEL_LAYER_CREATED",
    LINE_MODEL_LAYER_CREATED = "LINE_MODEL_LAYER_CREATED",
    DATA_TABLE_USED = "DATA_TABLE_USED",
    PLAN_SHARED = "PLAN_SHARED",
    PLAN_SEARCHED = "PLAN_SEARCHED",
    DATE_FILTER_USED = "DATE_FILTER_USED",
    DIGITAL_TWIN_USED = "DIGITAL_TWIN_USED",
    DELETE_LAYER = "DELETE_LAYER",
    IMPORT_LAYER = "IMPORT_LAYER",
    DISTANCE_ANALYSIS_USED = "DISTANCE_ANALYSIS_USED",
    WALK_TIME_ANALYSIS_USED = "WALK_TIME_ANALYSIS_USED",
    FLOW_RATE_ANALYSIS_USED = "FLOW_RATE_ANALYSIS_USED",
    AREA_ANALYSIS_USED = "AREA_ANALYSIS_USED",
    CAPACITY_ANALYSIS_USED = "CAPACITY_ANALYSIS_USED",
    COUNT_ANALYSIS_USED = "COUNT_ANALYSIS_USED",
    PLAN_3D_TERRAIN_USED = "PLAN_3D_TERRAIN_USED",
    PLAN_3D_BUILDINGS_USED = "PLAN_3D_BUILDINGS_USED",
    BACKGROUND_MAP_SELECTED = "BACKGROUND_MAP_SELECTED",
    SITEMAP_SELECTOR_USED = "SITEMAP_SELECTOR_USED",
    SITEMAP_LEVEL_USED = "SITEMAP_LEVEL_USED",
    COMMENT_CREATED = "COMMENT_CREATED",
    COMMENT_SELECTED = "COMMENT_SELECTED",
    MAP_OBJECT_CREATED = "MAP_OBJECT_CREATED",
    COPY_MAP_OBJECT_USED = "COPY_MAP_OBJECT_USED",
    FIXED_SHAPE_MAP_OBJECT_USED = "FIXED_SHAPE_MAP_OBJECT_USED",
    ROUTE_MAP_OBJECT_USED = "ROUTE_MAP_OBJECT_USED",
    DRAW_MAP_OBJECT_USED = "DRAW_MAP_OBJECT_USED",
    NOTIFICATIONS_VIEWED = "NOTIFICATIONS_VIEWED",
    CREATE_DATAFIELD = "CREATE_DATAFIELD",
    DELETE_DATAFIELD = "DELETE_DATAFIELD",
    MOVE_LAYER_TO_BACK = "MOVE_LAYER_TO_BACK",
    MOVE_LAYER_TO_FRONT = "MOVE_LAYER_TO_FRONT",
    MODEL_IMAGE_CHANGES = "MODEL_IMAGE_CHANGES",
    MODEL_COLOUR_CHANGES = "MODEL_COLOUR_CHANGES",
    SNAP_MAP_OBJECT_USED = "SNAP_MAP_OBJECT_USED"
}