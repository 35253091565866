import { css, Theme } from "@emotion/react";
import { media } from "./screen-sizes";
import { fontSizes, inlineTextIconMargin } from "./atomic-rules";
import { flexColumn, inlineTextIcon, textCenter, truncateText } from "./utility-classes";
import { lightScrollbarMixin } from "./scrollbar";

export const GlobalStyle = (theme: Theme) => css`
    ${lightScrollbarMixin(theme)}

    * {
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;
    }

    body {
        width: 100%;
        height: 100vh;
        height: 100dvh;
        overflow: hidden;
        background-color: ${theme.shades.five};

        .MuiList-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // mapbox attribution

    .mapboxgl-ctrl-attrib-button {
        display: none;
    }

    .mapboxgl-ctrl-bottom-right {
        display: flex;
        ${media.medium} {
            margin-right: 44px;
        }
    }

    .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
        margin: 0;
        padding: 2px 5px;
        background: hsla(0, 0%, 100%, 0.5);
    }

    .mapboxgl-ctrl-bottom-right .mapboxgl-compact {
        border-radius: 0;
        min-width: 12px;
    }

    a.mapboxgl-ctrl-logo {
        margin-left: 0;
        margin-top: -1px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact:after {
        background-color: transparent;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact:hover {
        margin-top: 0;
    }

    .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
        padding-left: 0;
    }

    ${media.extraSmall} {
        .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl:first-of-type {
            border-top-left-radius: 12px;
        }
        a.mapboxgl-ctrl-logo {
            width: 23px;
        }
    }

    ${media.medium} {
        .mapboxgl-ctrl-bottom-right {
            margin-right: 44px;
        }
    }

    // dont show mapbox logo on style previews
    .style-preview .mapboxgl-ctrl-bottom-right,
    .style-preview-small .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    // end of mapbox attribution

    body:not(.user-is-tabbing) button:focus {
        outline: none;
    }

    // Utility classes

    .${textCenter} {
        text-align: center;
    }

    .${flexColumn} {
        display: flex;
        flex-direction: column;
    }

    .${inlineTextIcon} {
        margin-left: ${inlineTextIconMargin};
    }

    .${truncateText} {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    // need this ag-grid specific styling in global because the drag and
    // drop ghost is appended to the end of the dom outside of the StyledTable
    .ag-dnd-ghost.ag-unselectable {
        padding: 6px 12px;
        background-color: ${theme.tertiaryColors.headerBackground};
        border: 1px solid ${theme.typographyColors.evenMoreSubdued};
        font-size: ${fontSizes.xSmall};
        transform: translate3d(8px, 12px, 0);
        border-radius: 4px;
        box-shadow: 0 0 2.5px 0.5px rgba(81, 82, 101, 0.26);
        gap: 5px;
        .svg-inline--fa {
            padding-right: 6px;
        }
    }
`;
