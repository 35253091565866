/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
import { v4 as uuid } from "uuid";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { feature, featureCollection, point, points, lineString, polygon, Units, Position } from "@turf/helpers";
import length from "@turf/length";
import area from "@turf/area";
import bearing from "@turf/bearing";
import distance from "@turf/distance";
import rhumbDistance from "@turf/rhumb-distance";
import rhumbDestination from "@turf/rhumb-destination";
import destination from "@turf/destination";
import rhumbBearing from "@turf/rhumb-bearing";
import along from "@turf/along";
import { convertAreaMeasurement, convertLengthMeasurement } from "@iventis/utilities";
import GeoJSON from "geojson";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { AnySupportedGeometry, MapObjectProperties } from "@iventis/map-types";
import { CompositionMapObject, FixedShapeSupportedGeometry, MapCursor, TypedFeature } from "../types/internal";
import { ContinueDrawingDirection } from "./continue-drawing";
import { mapErrors } from "../errors";
import { midPoint, opposite, getMidpointPositionWithPadding, getNormalAtVertex, furthest } from "./vectors";
import { MapState } from "../types/store-schema";
/** Earth's radius (approx.) */
const EARTH_RADIUS = 6371e3;

/** Converts degrees to radians */
const rad = (d: number) => (Math.PI * d) / 180;

/** Converts radians to degrees */
const deg = (r: number) => (r * 180) / Math.PI;

/** If input isn't within -180 < x < 180, then this function will output the matching bearing within that range */
const degreesInRange180 = (d: number) => (d < -180 ? 360 + d : d > 180 ? d - 360 : d);

export function addCoordinate(geometry: AnySupportedGeometry, additionalCoordinate: GeoJSON.Position): AnySupportedGeometry {
    if (geometry.type === "Polygon") {
        return {
            ...geometry,
            coordinates: addNodeToPolygon(geometry.coordinates, additionalCoordinate),
        };
    }
    if (geometry.type === "LineString") {
        return {
            ...geometry,
            coordinates: addNodeToLine(geometry.coordinates, additionalCoordinate),
        };
    }
    if (geometry.type === "Point") {
        return {
            ...geometry,
            coordinates: additionalCoordinate,
        };
    }
    throw new Error("Geometry type was neither Polygon or Linestring");
}

export function addMidpointCoordinate(geometry: AnySupportedGeometry, index: number): AnySupportedGeometry {
    if (geometry.type === "LineString") {
        const coordinates = addMidpointToLine(geometry.coordinates, index);

        return {
            ...geometry,
            coordinates,
        };
    }

    if (geometry.type === "Polygon") {
        const coordinates = [addMidpointToLine(geometry.coordinates[0], index)];

        return {
            ...geometry,
            coordinates,
        };
    }

    throw new Error("Geometry type is not supported");
}

export function addMidpointToLine(coordinates: GeoJSON.Position[], index: number) {
    const pointA = coordinates[index];
    const pointB = coordinates[index + 1];

    const midCoordinate = midPoint(pointA, pointB);
    const newCoordinatesReference = [...coordinates];
    newCoordinatesReference.splice(index + 1, 0, midCoordinate);

    return newCoordinatesReference;
}

export function getLineGeometryForMidpoints(feature: GeoJSON.Feature<AnySupportedGeometry>, isComposing = false) {
    if (feature.geometry.type === "Polygon") {
        const unWrappedGeometry = feature.geometry.coordinates[0];

        if (unWrappedGeometry === undefined) {
            return [];
        }

        if (isComposing) {
            return unWrappedGeometry.slice(0, unWrappedGeometry.length - 1);
        }
        return unWrappedGeometry;
    }

    if (feature.geometry.type === "LineString") {
        return feature.geometry.coordinates;
    }

    return [];
}

export function deleteCoordinate(geometry: AnySupportedGeometry, coordinate: GeoJSON.Position): AnySupportedGeometry {
    if (geometry === undefined) return undefined;
    let baseGeometry: GeoJSON.Position[];
    if (geometry.type === "Polygon") {
        [baseGeometry] = geometry.coordinates;
    }
    if (geometry.type === "LineString") {
        baseGeometry = geometry.coordinates;
    }

    const indexToDelete = baseGeometry.findIndex((position) => numbersEqualWithin(position, coordinate));

    const loopClosingNodeDeleted = geometry.type === "Polygon" && (indexToDelete === 0 || indexToDelete === baseGeometry.length - 1);

    const newGeometry = baseGeometry.filter((basePosition) => !numbersEqualWithin(basePosition, coordinate));

    let finalGeometry: GeoJSON.Position[];
    if (loopClosingNodeDeleted) {
        finalGeometry = [...newGeometry, newGeometry[0]];
    } else {
        finalGeometry = newGeometry;
    }

    if (geometry.type === "Polygon") {
        return {
            ...geometry,
            coordinates: [finalGeometry],
        };
    }

    if (geometry.type === "LineString") {
        return {
            ...geometry,
            coordinates: finalGeometry,
        };
    }

    throw new Error("Geometry type was neither Polygon or Linestring");
}

export function addNodeToLine(coordinates: GeoJSON.Position[], additionalCoordinate: GeoJSON.Position) {
    return [...coordinates, additionalCoordinate];
}

export function addNodeToPolygon(coordinates: GeoJSON.Position[][], additionalCoordinate: GeoJSON.Position) {
    if (coordinates.length === 0) {
        return [[additionalCoordinate, additionalCoordinate]];
    }
    const secondInstanceFirstPoint = getSecondInstanceOfFirstPoint(coordinates[0]);
    const coordinatesSingle = coordinates[0];
    return [[...coordinatesSingle.slice(0, secondInstanceFirstPoint), additionalCoordinate, coordinatesSingle[0]]];
}

export function replaceCoordinate(geometry: AnySupportedGeometry, originalCoordinate: GeoJSON.Position, newCoordinate: GeoJSON.Position): AnySupportedGeometry {
    if (geometry.type === "Polygon") {
        return {
            ...geometry,
            coordinates: replaceCoordinatePolygon(geometry.coordinates, originalCoordinate, newCoordinate),
        } as GeoJSON.Polygon;
    }
    if (geometry.type === "LineString") {
        return {
            ...geometry,
            coordinates: replaceCoordinateLine(geometry.coordinates, originalCoordinate, newCoordinate),
        };
    }
    if (geometry.type === "Point") {
        return {
            ...geometry,
            coordinates: newCoordinate,
        } as GeoJSON.Point;
    }
    throw new Error("Geometry type was neither Polygon or Linestring");
}

// eslint-disable-next-line space-before-function-paren
export function getIndexClosestCoordinate<T>(set: T[], coordinate: T, accessor: (a: T) => GeoJSON.Position) {
    let closestIndex = -1;
    let closestDiff = Infinity;

    const [lngSearch, latSearch] = accessor(coordinate);

    for (let i = 0; i < set.length; i += 1) {
        const [lngFromSet, latFromSet] = accessor(set[i]);
        const lngDiff = Math.abs(lngFromSet - lngSearch);
        const latDiff = Math.abs(latFromSet - latSearch);

        const totalDiff = lngDiff + latDiff;
        if (totalDiff < closestDiff) {
            closestDiff = totalDiff;
            closestIndex = i;
        }
    }

    return closestIndex;
}

/**
 * Gets the node closest to the passed coordinate inside the geometry and checks if that node is the first node in the geometry
 * @param geometry the geometry of the shape we are checking
 * @param coordinate the coordinate we are checking
 * @returns true if node coordinate is close to the first node in the geometry
 */
export function isFirstNodeInFeature(geometry: AnySupportedGeometry, coordinate: GeoJSON.Position): boolean {
    let pointsAreEqual = false;
    switch (geometry.type) {
        case "Polygon": {
            const closestPointIndex = getIndexClosestCoordinate(geometry.coordinates[0], coordinate, (a) => a);
            pointsAreEqual = geometry.coordinates[0][closestPointIndex] === geometry.coordinates[0][0];
            break;
        }
        case "LineString": {
            const closestPointIndex = getIndexClosestCoordinate(geometry.coordinates, coordinate, (a) => a);
            pointsAreEqual = geometry.coordinates[closestPointIndex] === geometry.coordinates[0];
            break;
        }
        case "Point": {
            pointsAreEqual = true;
            break;
        }
        default:
            throw new Error("Unknown type in case!");
    }
    return pointsAreEqual;
}

export function replaceCoordinateLine(coordinates: GeoJSON.Position[], originalCoordinate: GeoJSON.Position, newCoordinate: GeoJSON.Position): GeoJSON.Position[] {
    const indexEqual = getIndexClosestCoordinate(coordinates, originalCoordinate, (a) => a);
    if (indexEqual === -1) {
        throw new Error("Unable to find coordinate to replace");
    }

    return coordinates.map((coordinate, index) => (index === indexEqual ? newCoordinate : coordinate));
}

export function replaceCoordinatePolygon(coordinates: GeoJSON.Position[][], originalCoordinate: GeoJSON.Position, newCoordinate: GeoJSON.Position) {
    if (coordinates.length < 1) {
        throw new Error("Behaviour for multiple polygon features is not defined");
    }

    const singlePolygon = coordinates[0];

    const indexChanged = getIndexClosestCoordinate(singlePolygon, originalCoordinate, (a) => a);

    const loopCloserModified = indexChanged === 0 || indexChanged === singlePolygon.length - 1;

    if (loopCloserModified) {
        return [singlePolygon.map((coordinate, index) => (index === 0 || index === singlePolygon.length - 1 ? newCoordinate : coordinate))];
    }

    return [singlePolygon.map((coordinate, index) => (index === indexChanged ? newCoordinate : coordinate))];
}

export function numbersEqualWithin(a: number[], b: number[], tolerance = 0.000005) {
    const differenceLng = Math.abs(a[0] - b[0]);
    const differenceLat = Math.abs(a[1] - b[1]);
    return differenceLng <= tolerance && differenceLat <= tolerance;
}

export function getSecondInstanceOfFirstPoint(coordinates: GeoJSON.Position[]) {
    const firstPoint = coordinates[0];
    return coordinates.findIndex((p, i) => i !== 0 && p[0] === firstPoint[0] && p[1] === firstPoint[1]);
}

export function removeLoopClosingCoordinate(geometry: GeoJSON.Polygon | GeoJSON.LineString): GeoJSON.LineString {
    if (geometry.coordinates.length === 0) {
        return { type: "LineString", coordinates: [] } as GeoJSON.LineString;
    }

    const coordinates = getCoordinateList(geometry);
    const coordinateWithoutLast = coordinates.length <= 2 ? coordinates : coordinates.slice(0, coordinates.length - 1);

    return lineString(coordinateWithoutLast).geometry;
}

export function geometryToPointGeometry(object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>): GeoJSON.FeatureCollection<GeoJSON.Point> {
    const { geometry } = object;
    switch (geometry.type) {
        case "Polygon":
            return points(removeLoopClosingCoordinate(geometry).coordinates);
        case "LineString":
            if (object.properties.fixedShape != null) {
                const coords = geometry.coordinates.length > 1 ? removeLoopClosingCoordinate(geometry).coordinates : geometry.coordinates;
                return points(coords);
            }
            return points(geometry.coordinates);
        case "Point":
            return featureCollection([feature(geometry)]);
        default:
            throw new Error(`Geometry type ${(geometry as GeoJSON.Feature).type} does not have a case`);
    }
}

export function styleTypeToGeoJSONGeometryType(styleType: StyleType) {
    switch (styleType) {
        case StyleType.Point:
        case StyleType.Model:
        case StyleType.Icon:
            return "Point";
        case StyleType.Line:
        case StyleType.LineModel:
            return "LineString";
        case StyleType.Area:
            return "Polygon";
        default:
            throw new Error(`No conversion defined fot type '${styleType}'`);
    }
}

export function indexNext(index: number, direction: 1 | -1, length: number) {
    switch (direction) {
        case -1:
            return index === 0 ? length - 1 : index - 1;
        case 1:
            return index === length - 1 ? 0 : index + 1;
        default:
            throw new Error("Index must be the number -1 or 1");
    }
}

/**
 * Lines drawn by mapbox draw a rhumb line (constant bearing) instead of the shortest distance (variable bearing)
 * This is needed to get the correct midpoint for mapbox
 */
export function midpointRhumb(a: number[], b: number[]) {
    const bearing = rhumbBearing(a, b);
    const distance = rhumbDistance(a, b);
    const destination = rhumbDestination(a, distance / 2, bearing);
    return destination;
}

/** From point a, calculate a new position along the same bearing as b, distance away from a */
export function newDestinationRhumb(a: number[], b: number[], distance: number) {
    const bearing = rhumbBearing(a, b);
    const destination = rhumbDestination(a, distance, bearing);
    return destination;
}

/** Calculate rhumb distance from point a to b, in the give units. Meters by default */
export function distanceRhumb(a: number[], b: number[], units?: Units) {
    return rhumbDistance(a, b, units ? { units } : { units: "meters" });
}

/** Gets midpoints based on a line of constant bearing. This is how mapbox draws lines */
export function getMidpointsRhumb(coordinates: GeoJSON.Position[]) {
    const midpoints: GeoJSON.Point[] = [];

    for (let index = 0; index < coordinates.length - 1; index += 1) {
        const elementA = [...coordinates[index]];
        const elementB = [...coordinates[index + 1]];
        const midCoordinate = point(midpointRhumb(elementA, elementB).geometry.coordinates).geometry;
        midpoints.push(midCoordinate);
    }

    return midpoints;
}

/** Gets midpoints based on a line of constant bearing, and its' edge of the given shape */
export function getMidpointsRhumbWithEdge(coordinates: GeoJSON.Position[]) {
    const midpoints: { midpoint: GeoJSON.Point; edge: GeoJSON.Position[] }[] = [];

    for (let index = 0; index < coordinates.length - 1; index += 1) {
        const elementA = [...coordinates[index]];
        const elementB = [...coordinates[index + 1]];
        const midCoordinate = point(midpointRhumb(elementA, elementB).geometry.coordinates).geometry;
        midpoints.push({ midpoint: midCoordinate, edge: [elementA, elementB] });
    }

    return midpoints;
}

/** Gets midpoints based on a line of shortest distance with changing bearing */
export function getMidpointsShortest(coordinates: GeoJSON.Position[]) {
    const midpoints: GeoJSON.Point[] = [];

    for (let index = 0; index < coordinates.length - 1; index += 1) {
        const elementA = coordinates[index];
        const elementB = coordinates[index + 1];
        const midCoordinate = point(midPoint(elementA, elementB)).geometry;
        midpoints.push(midCoordinate);
    }

    return midpoints;
}

export function isCoordinateEqual(coordA: GeoJSON.Position, coordB: GeoJSON.Position): boolean {
    return coordA[0] === coordB[0] && coordA[1] === coordB[1];
}

export function lineStringCoordinateOnEnd(coordinate: GeoJSON.Position, geometry: GeoJSON.LineString) {
    const indexOfCoordinate = geometry.coordinates.findIndex((geometryCoordinate) => numbersEqualWithin(geometryCoordinate, coordinate));
    return indexOfCoordinate === 0 || indexOfCoordinate === geometry.coordinates.length - 1;
}

export function transformFeatureForNewDrawingPosition(feature: TypedFeature, newEndNode: GeoJSON.Position, direction: ContinueDrawingDirection) {
    if (feature.geometry.type === "Polygon") {
        return shiftGeometryPolygon(feature, newEndNode, direction);
    }
    if (feature.geometry.type === "LineString") {
        return rotateGeometryLine(feature, newEndNode);
    }
    throw new Error("Could not transform provided geometry type");
}

export function rotateGeometryLine(feature: TypedFeature, newEndNode: GeoJSON.Position) {
    const geometry = feature.geometry as GeoJSON.LineString;
    if (numbersEqualWithin(geometry.coordinates[0], newEndNode)) {
        // First element needs to be the last element now
        const newCoordinates = [...geometry.coordinates].reverse();
        return lineString(newCoordinates, feature.properties);
    }
    if (numbersEqualWithin(geometry.coordinates[geometry.coordinates.length - 1], newEndNode)) {
        return feature;
    }
    throw new Error("Provided node was not the first or last in the line string");
}

export function shiftGeometryPolygon(feature: TypedFeature, newEndNode: GeoJSON.Position, direction: ContinueDrawingDirection): TypedFeature {
    // eslint-disable-next-line prefer-destructuring
    const geometry = feature.geometry as GeoJSON.Polygon;
    const geometryStripped = geometry.coordinates[0];
    if (geometryStripped.length < 4) {
        throw new Error("Polygon has too few nodes");
    }
    const geometryWithoutLoopClosing = geometryStripped.slice(0, geometryStripped.length - 1);

    if (direction === ContinueDrawingDirection.BEHIND) {
        geometryWithoutLoopClosing.reverse();
    }

    let result: GeoJSON.Position[];

    const indexNewEnd = geometryWithoutLoopClosing.findIndex((geometryPosition) => numbersEqualWithin(geometryPosition, newEndNode));

    if (indexNewEnd === -1) {
        throw new Error("Could not the new end node within the provided feature's geometry");
    }

    if (indexNewEnd === geometryWithoutLoopClosing.length - 1) {
        // No change required since the node we're modifying is at the end (which could be after a reverse, as above)
        result = geometryWithoutLoopClosing;
    } else {
        result = geometryWithoutLoopClosing.slice(indexNewEnd + 1, geometryWithoutLoopClosing.length).concat(geometryWithoutLoopClosing.slice(0, indexNewEnd + 1));
    }

    const finalGeometry = [...result, result[0]];
    return polygon([finalGeometry], feature.properties);
}
/** Given a map projection, returns a function which returns a position on the map that is relative to a given coordinate and the features shape it belongs to. Returns null if the closest point in the collection is equal */
export const getRelativePosition = (project: (coordinates: GeoJSON.Position) => [number, number], unproject: (screen: [number, number]) => GeoJSON.Position) => (
    collection: GeoJSON.FeatureCollection<GeoJSON.Point>,
    coordinate: GeoJSON.Position,
    distanceFromCoordinate: number,
    /** Decides whether to position inside or outside the shape */
    inside = true
) => {
    // Convert positional data from geographical to screenspace
    const coordinateScreen = project(coordinate);
    const geometryScreen = collection.features.map((f) => project(f.geometry.coordinates));

    const { normal: directionedUnitVector, finalPoint } = getNormalAtVertex(coordinateScreen, geometryScreen, inside, (a, b) => numbersEqualWithin(a, b, 0.1));

    // If the final point is no distance from origin, return null
    if (Number.isNaN(finalPoint[0]) || Number.isNaN(finalPoint[1])) {
        return null;
    }

    const oppositeDirectionedUnitVector = opposite(directionedUnitVector);

    const xRelative = oppositeDirectionedUnitVector[0] * distanceFromCoordinate;
    const yRelative = oppositeDirectionedUnitVector[1] * distanceFromCoordinate;

    const xAbsolute = coordinateScreen[0] + xRelative;
    const yAbsolute = coordinateScreen[1] + yRelative;

    const unProjectedResult = unproject([xAbsolute, yAbsolute]);
    return unProjectedResult;
};

/** Gets the midpoints of the closest segments to the given coordinate, plus a given offset */
export const getClosestVectorMidpoints = (
    collection: GeoJSON.FeatureCollection<GeoJSON.Point>,
    coordinate: GeoJSON.Position,
    padding: number,
    project: (coordinates: GeoJSON.Position) => [number, number],
    unproject: (screen: [number, number]) => GeoJSON.Position
): Record<"coordinate" | "midpoint", GeoJSON.Position>[] => {
    // Convert positional data from geographical to screenspace
    const coordinateScreen = project(coordinate);
    const geometryScreen = collection.features.map((f) => project(f.geometry.coordinates));

    const indexTouched = geometryScreen.findIndex((geometryCoord) => numbersEqualWithin(geometryCoord, coordinateScreen, 0.1));
    if (indexTouched === -1) {
        throw new Error(mapErrors.unmatchedNode);
    }

    const previousCoord = geometryScreen[indexNext(indexTouched, 1, geometryScreen.length)];
    const nextCoord = geometryScreen[indexNext(indexTouched, -1, geometryScreen.length)];

    return [
        { coordinate: unproject(previousCoord), midpoint: unproject(getMidpointPositionWithPadding([coordinateScreen, previousCoord], padding, geometryScreen, false)) },
        { coordinate: unproject(nextCoord), midpoint: unproject(getMidpointPositionWithPadding([coordinateScreen, nextCoord], padding, geometryScreen, false)) },
    ];
};

/** Gets the midpoint of the closest segment to the given coordinate, plus a given offset */
export const getClosestVectorMidpoint = (
    collection: GeoJSON.FeatureCollection<GeoJSON.Point>,
    coordinate: GeoJSON.Position,
    padding: number,
    project: (coordinates: GeoJSON.Position) => [number, number],
    unproject: (screen: [number, number]) => GeoJSON.Position
): Record<"coordinate" | "midpoint", GeoJSON.Position>[] => {
    // Convert positional data from geographical to screenspace
    const coordinateScreen = project(coordinate);
    const geometryScreen = collection.features.map((f) => project(f.geometry.coordinates));

    const indexTouched = geometryScreen.findIndex((geometryCoord) => numbersEqualWithin(geometryCoord, coordinateScreen, 0.1));
    if (indexTouched === -1) {
        throw new Error(mapErrors.unmatchedNode);
    }

    const nextCoord = geometryScreen[indexNext(indexTouched, 1, geometryScreen.length)];

    return [{ coordinate: unproject(nextCoord), midpoint: unproject(getMidpointPositionWithPadding([coordinateScreen, nextCoord], padding, geometryScreen, false)) }];
};

export const getLengthInAppropriateUnits = (feature: GeoJSON.Feature<GeoJSON.LineString>, unitOfMeasurement: UnitOfMeasurement) => {
    const lengthMeasurement = length(feature, { units: "meters" });
    return convertLengthMeasurement(lengthMeasurement, unitOfMeasurement);
};

export const getAreaInAppropriateUnits = (feature: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.LineString>, unitOfMeasurement: UnitOfMeasurement) => {
    const areaMeasurement = feature.geometry.type === "LineString" ? area(polygon([feature.geometry.coordinates])) : area(feature);
    return convertAreaMeasurement(areaMeasurement, unitOfMeasurement);
};

export const minimumNumberOfPointsToShowMeasurement = (type: GeoJSON.GeoJsonGeometryTypes) => {
    switch (type) {
        case "Polygon":
            return 2;
        case "LineString":
            return 2;
        default:
            throw new Error(`Cannot show measurements for geometry type: ${type}`);
    }
};

export function getNewGeoJsonState(state: MapState, objects: CompositionMapObject[], overwriteExistingObjects = true) {
    const currentStoreValue = state.geoJSON.value;
    const newStoreValue = objects.reduce((cumulative, object) => {
        const existingLayerData = cumulative[object.layerId] || [];

        const newObject = {
            feature: object.geojson,
            objectId: object.objectId,
            waypoints: object.waypoints,
        };

        const newObjectContents = overwriteExistingObjects
            ? // If overriding, remove data that already exists for this object ID and place the new object in
              [...existingLayerData.filter(({ objectId: previousObjectId }) => previousObjectId !== object.objectId), newObject]
            : // If not overriding, and the new object is in the existing data, do not take the new object at all, just keep existing data
            existingLayerData.some(({ objectId: existingObjectId }) => existingObjectId === newObject.objectId)
            ? existingLayerData
            : // Otherwise, append the new object to the existing data, since we don't already have it
              [...existingLayerData, newObject];

        return { ...cumulative, [object.layerId]: newObjectContents };
    }, currentStoreValue);

    return newStoreValue;
}

export function setLocalGeoJson(state: MapState, objects: CompositionMapObject[], overwriteExistingObjects = true) {
    // eslint-disable-next-line no-param-reassign
    state.geoJSON.value = getNewGeoJsonState(state, objects, overwriteExistingObjects);
    // eslint-disable-next-line no-param-reassign
    state.geoJSON.stamp = uuid();
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export function resizeRectangle(currentGeom: FixedShapeSupportedGeometry, targetCoord: GeoJSON.Position, mapBearing = 0): FixedShapeSupportedGeometry {
    if (currentGeom.coordinates.length === 0) {
        return {
            ...currentGeom,
            coordinates: [currentGeom.type === "Polygon" ? [targetCoord, targetCoord] : targetCoord],
        } as FixedShapeSupportedGeometry;
    }
    // Fixed coordinate
    const anchorCoord = furthest(getCoordinateList(currentGeom), (a) => a, targetCoord);

    const coords = createRectangleFromTwoPoints(anchorCoord, targetCoord, mapBearing);

    return updateCoordinates(currentGeom, coords) as FixedShapeSupportedGeometry;
}

/** Given two coordinates, creates a rectangle relative to the given bearing */
export function createRectangleFromTwoPoints(anchorCoord: GeoJSON.Position, targetCoord: GeoJSON.Position, mapBearing = 0): GeoJSON.Position[] {
    // Distance from anchor to target (first click to current mouse position)
    const targetDistance = distance(anchorCoord, targetCoord, { units: "meters" });
    // Bearing from anchor to target
    const targetBearing = bearing(anchorCoord, targetCoord);
    // Combined bearing of parameter offset (mapBearing) and the above derived bearing
    const resultingBearing = degreesInRange180(targetBearing - mapBearing);
    // Angle in right angle used to calculate the y axis line of the rectangle
    const thetaY = (() => {
        if ((resultingBearing >= 0 && resultingBearing < 90) || (resultingBearing >= -90 && resultingBearing < 0)) {
            return Math.abs(resultingBearing);
        }
        if ((resultingBearing >= 90 && resultingBearing <= 180) || (resultingBearing >= -180 && resultingBearing < -90)) {
            return 180 - Math.abs(resultingBearing);
        }
        throw new Error(`Bearing of ${resultingBearing} not considered`);
    })();
    // Distance from anchor coordinate along relative y-axis
    const distanceY = Math.abs(targetDistance * Math.cos(rad(thetaY)));
    // Actual direction the line should travel from anchor coordinate
    const angleY = (resultingBearing >= -90 && resultingBearing < 90 ? mapBearing : mapBearing + 180) % 360;

    // Angle in right angle used to calculate the x axis line of the rectangle
    const thetaX = (() => {
        if ((resultingBearing >= 0 && resultingBearing < 90) || (resultingBearing >= -90 && resultingBearing < 0)) {
            return 90 - Math.abs(resultingBearing);
        }
        if ((resultingBearing >= 90 && resultingBearing <= 180) || (resultingBearing >= -180 && resultingBearing < -90)) {
            return Math.abs(resultingBearing) - 90;
        }
        throw new Error(`Bearing of ${resultingBearing} not considered`);
    })();
    // Distance from anchor coordinate along relative x-axis
    const distanceX = Math.abs(targetDistance * Math.cos(rad(thetaX)));
    // Actual direction the line should travel from anchor coordinate
    const angleX = (resultingBearing >= 0 && resultingBearing < 180 ? mapBearing + 90 : mapBearing + 270) % 360;

    return [
        anchorCoord,
        destinationFromDistanceBearing([rad(anchorCoord[0]), rad(anchorCoord[1])], rad(angleX), distanceX),
        targetCoord,
        destinationFromDistanceBearing([rad(anchorCoord[0]), rad(anchorCoord[1])], rad(angleY), distanceY),
        anchorCoord,
    ];
}

/** Finds a destination point based on bearing and distance from a base coordinate (in radians) */
export function destinationFromDistanceBearing(baseCoord: number[], bearing: number, distance: number) {
    const lat = Math.asin(Math.sin(baseCoord[1]) * Math.cos(distance / EARTH_RADIUS) + Math.cos(baseCoord[1]) * Math.sin(distance / EARTH_RADIUS) * Math.cos(bearing));
    const lon =
        baseCoord[0] +
        Math.atan2(Math.sin(bearing) * Math.sin(distance / EARTH_RADIUS) * Math.cos(baseCoord[1]), Math.cos(distance / EARTH_RADIUS) - Math.sin(baseCoord[1]) * Math.sin(lat));

    return [deg(lon), deg(lat)];
}

/** Finds the bearing of a given rectangle */
export function findBearingOfRectangle(geometry: FixedShapeSupportedGeometry) {
    const start = getCoordinateList(geometry)[1];
    const end = getCoordinateList(geometry)[2];
    return bearing(start, end);
}

export function removeCoordinate(coordinates: GeoJSON.Position[], coordinate: GeoJSON.Position): GeoJSON.Position[] {
    const index = getIndexClosestCoordinate(coordinates, coordinate, (a) => a);
    return coordinates.filter((_, i) => i !== index);
}

export function getCoordinateList(geometry: GeoJSON.LineString | GeoJSON.Polygon) {
    return geometry.type === "Polygon" ? geometry.coordinates[0] : geometry.coordinates;
}

export function updateCoordinates<TGeom extends GeoJSON.LineString | GeoJSON.Polygon>(geometry: TGeom, coordinates: GeoJSON.Position[]): TGeom {
    const validCoordinates = ensureCoordinatesListValid(coordinates);
    return { ...geometry, coordinates: geometry.type === "Polygon" ? [validCoordinates] : validCoordinates };
}

/** Resizes the rectangle along a single "axis" (relative to the rotation of the rectangle) */
export function resizeRectangleOneDirectionWithMidpoints(geometry: FixedShapeSupportedGeometry, anchorMidpoint: GeoJSON.Position, targetMidpoint: GeoJSON.Position) {
    const midpoints = getMidpointsRhumbWithEdge(getCoordinateList(geometry));
    const indexOfAnchor = getIndexClosestCoordinate(midpoints, { midpoint: point(anchorMidpoint).geometry, edge: [] }, (a) => a.midpoint.coordinates);
    const indexOfMoving = (indexOfAnchor + 2) % 4;

    // Opposite the anchor lies the moving midpoint (the one we're frequently updating)
    const movingMidpoint = midpoints[indexOfMoving].midpoint.coordinates;

    // The bearing we are moving along (think of this as an axis). Using rhumb to follow same curvature as mapbox lines
    const rectangleBearing = ensure360Bearing(rhumbBearing(anchorMidpoint, movingMidpoint));
    // The bearing from anchor to cursor
    const cursorBearing = ensure360Bearing(bearing(anchorMidpoint, targetMidpoint));
    // Distance from anchor to cursor
    const cursorDistance = distance(anchorMidpoint, targetMidpoint, { units: "meters" });
    // The resulting angle when comparing these two bearings (to form a right angle triangle)
    const differenceInDirections = (rectangleBearing - cursorBearing) % 360;
    // The snapped distance along the rectangle axis relative to the cursor position
    const snappedDistance = cursorDistance * Math.cos(rad(differenceInDirections));

    // Get the fixed anchor edge, so we can re-construct the rectangle
    const anchorEdge = midpoints[indexOfAnchor].edge;

    // Get the opposite edge of the rectangle (the one that have moved)
    const movedEnds = [
        rhumbDestination(anchorEdge[0], snappedDistance, rectangleBearing, { units: "meters" }).geometry.coordinates,
        rhumbDestination(anchorEdge[1], snappedDistance, rectangleBearing, { units: "meters" }).geometry.coordinates,
    ];

    // Make a new array and assign the coordinates
    const complete = [...getCoordinateList(geometry)].slice(0, 4);

    complete[indexOfMoving] = movedEnds[1];
    complete[indexOfMoving + 1 === 4 ? 0 : indexOfMoving + 1] = movedEnds[0];

    complete.push(complete[0]);

    return updateCoordinates(geometry, complete);
}

/** Resizes the rectangle based on the top left coord of the rectangle */
export function resizeRectangleInAxis(geometry: FixedShapeSupportedGeometry, newDistance: number, axis: "x" | "y") {
    // Setup (array has to be cloned, otherwise you will get a readonly error)
    const rectCoords = [...getCoordinateList(geometry)];

    // Get coords
    // The anchor sides we are resizing from, will stay the same
    const anchorCoords = axis === "x" ? [rectCoords[0], rectCoords[3]] : [rectCoords[0], rectCoords[1]];
    // The target sides we are resizing
    const targetCoords = axis === "x" ? [rectCoords[1], rectCoords[2]] : [rectCoords[3], rectCoords[2]];

    // Get bearing from anchor to target side
    const bearing = ensure360Bearing(rhumbBearing(anchorCoords[0], targetCoords[0]));

    // Calculate the new destinations for the target side
    const newPoints = anchorCoords.map((coord) => rhumbDestination(coord, newDistance, bearing, { units: "meters" }).geometry.coordinates);

    // Update the coordinates in place
    rectCoords[axis === "x" ? 1 : 3] = newPoints[0];
    rectCoords[2] = newPoints[1];

    return updateCoordinates(geometry, rectCoords);
}

/** Given a bearing with range -180 < x < 180, or bearing > 360, returns the correct bearing within range 0 < x < 360 */
export function ensure360Bearing(b: number) {
    return (b < 0 ? 360 + b : b) % 360;
}

export function getOneDirectionalCursor(cursorBearing: number, mapBearing: number) {
    const cursorSection = cursorBearing % 180;
    const mapSection = mapBearing % 180;
    const diff = mapSection - cursorSection;
    const offset = diff < 0 ? diff + 180 : diff;
    if (offset < 22.5) {
        return MapCursor.NS_RESIZE;
    }
    if (offset < 67.5) {
        return MapCursor.NWSE_RESIZE;
    }
    if (offset < 112.5) {
        return MapCursor.EW_RESIZE;
    }
    if (offset < 157.5) {
        return MapCursor.NESW_RESIZE;
    }
    return MapCursor.NS_RESIZE;
}

export function getMidPointOfLineString(lineString: GeoJSON.LineString) {
    // Get distance of line string
    const distanceOfLine = length(feature(lineString), { units: "meters" });
    // Find halfway distance along the line
    return along(lineString, distanceOfLine / 2, { units: "meters" });
}

/**
 * Check if the composition has at least 3 coordinates for a polygon, or two coordinates for a line, points need two coordinates to be valid.
 * @param composition The composition to be checked
 * @returns True if the composition is valid
 */
export function isCompositionValid(composition: AnySupportedGeometry): boolean {
    if (composition === undefined) return false;
    switch (composition.type) {
        case "Polygon":
            // Make sure we have at least 3 (4 since we have the 1st point twice) points for a polygon to be valid
            if (composition.coordinates[0]?.length >= 4 && isCoordinateEqual(composition.coordinates[0][0], composition.coordinates[0][composition.coordinates[0].length - 1])) {
                return true;
            }
            return false;
        case "LineString":
            // Make sure we have at least 2 points for a linestring to be valid
            if (composition.coordinates?.length >= 2) return true;
            return false;
        case "Point":
            // Points should only have two coordinates
            return composition.coordinates.length === 2;
        default:
            // We have an unsupported type
            return false;
    }
}

function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

function isValidLongitude(value: number): boolean {
    return Math.abs(value) <= 180;
}

function isValidLatitude(value: number): boolean {
    return Math.abs(value) <= 90;
}

/**
 * Checks if a coordinate has a valid longitude and latitude. These can be -180,180 and -90,90 respectively.
 * If not, correct and return with a valid coordinate clamped to their respective ranges
 */
export function ensureCoordinatesValid(coordinates: GeoJSON.Position): GeoJSON.Position {
    const cleanCoord = coordinates;
    if (!isValidLongitude(coordinates[0])) {
        cleanCoord[0] = clamp(coordinates[0], -180, 180);
    }
    if (!isValidLatitude(coordinates[1])) {
        cleanCoord[1] = clamp(coordinates[1], -90, 90);
    }
    return cleanCoord;
}

/**
 * Checks and corrects the coordinates within a list.
 */
export function ensureCoordinatesListValid(coordinates: GeoJSON.Position[]): GeoJSON.Position[] {
    return coordinates.map(ensureCoordinatesValid);
}

/**
 * Moves the first and last point by the given offset and returns a new LineString
 *
 * @param originalFeature - Feature where the offset is being applied to
 * @param offsetAmount - Amount in meters the offset will be
 * @returns - LineString with update coordinates and the same properties as the original feature
 */
export function offsetLineByDistance<TProperties>(
    originalFeature: GeoJSON.Feature<GeoJSON.LineString, TProperties>,
    offsetAmount: number
): GeoJSON.Feature<GeoJSON.LineString, TProperties> {
    if (originalFeature.geometry.coordinates.length < 2) {
        return originalFeature;
    }
    const amountOfCoordinates = originalFeature.geometry.coordinates.length;

    // Calculate the new starting coordinate
    const updatedStartingCoordinate = destination(
        originalFeature.geometry.coordinates[0],
        offsetAmount,
        bearing(originalFeature.geometry.coordinates[0], originalFeature.geometry.coordinates[1]),
        {
            units: "meters",
        }
    );

    // Calculate the new ending coordinate
    const updatedEndingCoordinate = destination(
        originalFeature.geometry.coordinates[amountOfCoordinates - 1],
        offsetAmount,
        bearing(originalFeature.geometry.coordinates[amountOfCoordinates - 2], originalFeature.geometry.coordinates[amountOfCoordinates - 1]),
        { units: "meters" }
    );

    // Replace the first and last coordinates with the update ones
    const coordinates = [updatedStartingCoordinate.geometry.coordinates, ...originalFeature.geometry.coordinates.slice(1, -1), updatedEndingCoordinate.geometry.coordinates];

    return lineString(coordinates, originalFeature.properties);
}

/** Checks if a coordinate is present within a given geometry */
export function isCoordinatePresentInGeometry(geometry: AnySupportedGeometry, coordinate: Position) {
    if (geometry == null || coordinate == null) {
        return false;
    }

    switch (geometry.type) {
        case "Polygon":
            return geometry.coordinates[0].some((coord) => numbersEqualWithin(coord, coordinate));
        case "LineString":
            return geometry.coordinates.some((coord) => numbersEqualWithin(coord, coordinate));
        case "Point":
            return numbersEqualWithin(geometry.coordinates, coordinate);
        default:
            return false;
    }
}
